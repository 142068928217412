import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { globalMemory } from "../globals";

const functionsDatum = {
  //---------------------------------------------------------------------------
  // aktuelles Datum ermitteln (DD.MM.YYYY)
  getToday() {
    let currentTime = new Date();

    let day = currentTime.getDate();
    let dayStr = day.toString();
    if (day < 10) dayStr = "0" + dayStr; // führende Null bei einstelligen Tagen

    let month = currentTime.getMonth() + 1;
    let monthStr = month.toString();
    if (month < 10) monthStr = "0" + monthStr; // führende Null bei einstelligen Monaten

    let year = currentTime.getFullYear();
    return dayStr + "." + monthStr + "." + year;
  },

  //---------------------------------------------------------------------------
  // Datum UTC (2020-11-26T18:30:15.502Z) zu MEZ (26.11.2020) wandeln
  datumUTCToPlain(datumUTC: string) {
    const datumO: Date = new Date(datumUTC);

    let dayStr = datumO.getDate().toString();
    if (datumO.getDate() < 10) dayStr = "0" + dayStr; // führende Null bei einstelligen Tagen

    let monthStr = (datumO.getMonth() + 1).toString(); // getMonth(): 0.. 11
    if (datumO.getMonth() + 1 < 10) monthStr = "0" + monthStr; // führende Null bei einstelligen Monaten

    return dayStr + "." + monthStr + "." + datumO.getFullYear();
  },
};

export default functionsDatum;

//----------------------------------------------------------------------------------------------------------------------
// Filialname anhand des Filialtoken holen
export const convertToLocalDate = (stringGot: string) => {
  return stringGot
    .replace("Monday", "Montag")
    .replace("Tuesday", "Dienstag")
    .replace("Wednesday", "Mittwoch")
    .replace("Thursday", "Donnerstag")
    .replace("Friday", "Freitag")
    .replace("Saturday", "Samstag")
    .replace("Sunday", "Sonntag");
};

export const disableDateBefore: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs(globalMemory.aktUmstellungsdatum).endOf("day");
  //return current && current < dayjs("2000-01-01").endOf("day");
};
