import { createHash } from "crypto";
import { getWertFromlocalStorage } from "./functions";
import { APIEnumRolle } from "../types/apiTypes";
import { useAppStore } from "../store/zustandAppStore";
import { useLogin } from "../hooks/useLogin";

/**
 * Checks on API if actual User has Roles SM or CA
 * returns boolean
 * TODO
 */
export const isSalonManagerAndAbove = () => {
  let rolle = getWertFromlocalStorage("loginInfo", "rolle");
  return (
    rolle === APIEnumRolle.APIDB_ENUM_GESCHAEFTSFUEHRUNG ||
    rolle === APIEnumRolle.APIDB_ENUM_FILIALLEITUNG ||
    rolle === APIEnumRolle.APIDB_ENUM_ADMIN
  );
};

/**
 * Checks in API if actual User has CA Role
 * returns boolean
 * TODO
 */
export const isCompanyAdministrator = () => {
  let rolle = getWertFromlocalStorage("loginInfo", "rolle");
  return rolle === APIEnumRolle.APIDB_ENUM_GESCHAEFTSFUEHRUNG || rolle === APIEnumRolle.APIDB_ENUM_ADMIN;
};

/**
 * Hashes a string in SHA256
 * @param stringGot
 * returns hashed String
 */
export const hashString = (stringGot: string) => {
  return createHash("sha256").update(stringGot).digest("hex");
};
