import React, { useMemo } from "react";
import { Breadcrumb, Button, Col, Dropdown, Form, Layout, Result, Row, Select, Spin, Typography } from "antd";
import style from "./Arbeitszeiten.module.css";
import moment from "moment";
import { useParams } from "react-router";
import { useVerbuende } from "../../../hooks/useVerbuende";
import { useFilialen } from "../../../hooks/useFilialen";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../../../constants";
import { APIArbeitszeiten, APIErfolgRueckmeldung, APISetIstArbeitszeitPause } from "../../../types/apiTypes";
import { fetchData, getWertFromlocalStorage, searchFilialtokenInArray } from "../../../utils/functions";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { useArbeitszeiten } from "../../../hooks/useArbeitszeiten";
import IWMonthPicker from "../../uiElemente/IWDatePicker/IWDatePicker";

import { exportToCSV } from "../Datev/utils";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { tippy } from "@tippyjs/react";
import { StyleWrapper } from "../SollArbeitszeiten/SollArbeitszeiten";
import { Dictionary, EventImpl } from "@fullcalendar/core/internal";
import { isCompanyAdministrator } from "../../../utils/functionsSecurity";

import { EventMountArg } from "@fullcalendar/core";

import { ItemType } from "antd/es/menu/hooks/useItems";
import { EditTwoTone } from "@ant-design/icons";
import { ColSpec } from "@fullcalendar/resource";
import IWKorrektur from "./IWKorrektur";
import IWIstArbeitszeitenModal from "./IWIstArbeitszeitenModal";
import { globalMemory } from "../../../globals";
import IWConfirmationModal from "../../uiElemente/IWConfirmationModal/IWConfirmationModal";
import IWInformationModal from "../../uiElemente/IWInformationModal/IWInformationModal";
import { getQuerydeleteIstArbeitszeit, getQuerySetIstArbeitszeit } from "../../../hooks/queriesIstArbeitszeiten";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { useArbeitszeitStore } from "../../../store/zustandArbeitszeitenStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconDatenFehlen from "../../images/Daten_Fehlen_icon.svg";

const { Content } = Layout;
const { Title } = Typography;

const Arbeitszeiten = () => {
  const elementIconClock = (
    /* @ts-ignore langer comment wegen ts-ignore____________________________________*/
    <FontAwesomeIcon icon="fa-light fa-clock" style={{ marginLeft: "15px" }} color={"#1188d8"} />
  );
  // @ts-ignore
  const elementIconTherm = <FontAwesomeIcon icon="fa-light fa-light fa-face-thermometer" color={"#1188d8"} />;
  // @ts-ignore
  const elementIconThermSum = <FontAwesomeIcon icon="fa-light fa-light fa-face-thermometer" color={"#1188d8"} />;
  // @ts-ignore
  const elementIconBeach = <FontAwesomeIcon icon="fa-light fa-umbrella-beach" color={"#1188d8"} />;
  // @ts-ignore
  const elementIconBeachSum = <FontAwesomeIcon icon="fa-light fa-umbrella-beach" color={"#1188d8"} />;
  /**
   * Farbwerte fuer Kalender
   */
  const colorArbeitstag = "#8EB8F0";
  const colorUrlaubstag = "#43BF99";
  const colorKrankheitstag = "#FC686F";
  const colorSchule = "#8085D9";
  const colorSonstiges = "#8085D9";
  const colorWochenende = "#BBBBBB";
  const colorFeiertag = "#BBBBBB";
  const colorRuhetag = "#A8DBC2";
  const colorLeerEvent = "#DDDDDD";

  let calendarRef = React.createRef<FullCalendar>();

  const { Title } = Typography;

  const {
    setDisplayFirstOpen,
    displayModalMenuPosX,
    displayModalMenuPosY,
    displayModalMenuItems,
    displayModalMenu,
    setDisplayModalMenuItems,
    setDisplayModalMenuPosX,
    setDisplayModalMenuPosY,
    setDisplayModalMenu,
  } = useGenericStore();

  const {
    aktFilialToken,
    setAktFilialToken,
    setSelectedData,
    selectedData,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isModalInformationVisible,
    isModalConfirmationVisible,
    setIsModalConfirmationVisible,
    setIsModalIstZeitenVisible,
    setIsModalKorrekturVisible,
    setIsModalInformationVisible,
    submitValues,
    setSubmitValues,
    detailDataIstArbeitszeiten,
    setDetailDataIstArbeitszeiten,
    detailDataKorrektur,
    setDetailDataKorrektur,
    setPausenVisible,
  } = useArbeitszeitStore();

  const { Option } = Select;

  const { type, token = "" } = useParams<{ type: string; token: string }>();

  /**
   * Gibt an welcher ArrayIndex der Tabellendaten genutzt wird - in dem Fall Index 0 für den MA-Namen
   */
  const selectRow = 0;

  let isVerbund = type === "verbund";
  let isFiliale = type === "filiale";
  let filialToken = token ? [token] : [];
  let alias = "";

  let outputDataCSV: string[][] = [];

  if (token !== aktFilialToken) {
    setAktFilialToken(token);
    //setStartDate(DEFAULT_DATEPICKER_START_MOMENT.format());
    //setEndDate(DEFAULT_DATEPICKER_END_MOMENT.hour(0).minute(0).second(0).format());
  }

  let aktUmstellungsDetails = null;
  let foundUmstellungDatum = "2000-01-01";
  let memoryUmstDatum: dayjs.Dayjs = dayjs(foundUmstellungDatum);
  // Alle Filialen durchlaufen
  for (let i = 0; i < globalMemory.umstellungJeFilialeStammdaten.length; i++) {
    // wenn alle Filialen oder Verbünde
    if (!isFiliale) {
      let aktUmstellungsDetailsAll = globalMemory.umstellungJeFilialeStammdaten[i].datumUmstellungNeuesAZeitModell;
      let datumString = aktUmstellungsDetailsAll.split("T");
      let aktUmstDatum: dayjs.Dayjs = dayjs(datumString[0]);
      if (aktUmstDatum > memoryUmstDatum) {
        foundUmstellungDatum = aktUmstDatum.format("YYYY-MM-DD");
      }
      //wenn einzelne Filiale
    } else if (globalMemory.umstellungJeFilialeStammdaten[i].filialToken === filialToken[0]) {
      aktUmstellungsDetails = globalMemory.umstellungJeFilialeStammdaten[i].datumUmstellungNeuesAZeitModell;
      let datumTemp = aktUmstellungsDetails.split("T");
      foundUmstellungDatum = datumTemp[0];
    }
  }
  globalMemory.aktUmstellungsdatum = foundUmstellungDatum;

  if (startDate.includes("Invalid")) {
    setStartDate(DEFAULT_DATEPICKER_START_MOMENT.format());
    setEndDate(DEFAULT_DATEPICKER_END_MOMENT.hour(0).minute(0).second(0).format());
  }

  // Verbünde und Filialen laden, wenn wir auf einer Verbund- bzw. Filialseite sind
  const { verbuende } = useVerbuende({ enabled: isVerbund });
  const { filialen } = useFilialen({ enabled: isFiliale });

  if (isVerbund) {
    const verbund = verbuende.find((v) => v.verbundToken === token);
    if (verbund) {
      filialToken = verbund.filialen;
      alias = verbund.alias;
    }
  }

  if (isFiliale) {
    const filiale = filialen.find((f) => f.filialToken === token);
    if (filiale) alias = filiale.alias;
  }

  const { stammdaten } = useStammdaten();

  // Tabellendaten von API holen
  // eslint-disable-next-line
  const { isLoading, arbeitszeiten } = useArbeitszeiten({
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
    filialToken: filialToken,
    startDate: startDate,
    endDate: endDate,
  });

  var booleanDatenAktuell: boolean = true;

  // Tabellendaten generieren
  const arbeitszeitenRAW: APIArbeitszeiten = arbeitszeiten;

  /**
   * Options für Select aus den Mitarbeiternamen generieren
   */
  const selectData = useMemo(() => {
    const tableRAW = arbeitszeitenRAW.tabZeilen;
    let arrZeilen: {}[] = [];
    let arrNames: String[] = [];

    if (tableRAW) {
      for (const apiStatistikTabZeile of tableRAW) {
        arrNames.push(apiStatistikTabZeile.spalten[selectRow]);
      }
      arrNames.sort();
      for (const zeileName of arrNames) {
        arrZeilen.push(
          <Option key={"" + zeileName} value={"" + zeileName}>
            {"" + zeileName}
          </Option>
        );
      }
    }

    //Checke Ob Daten aktuell sind
    if (token != "" && arbeitszeitenRAW.metaData != undefined) {
      booleanDatenAktuell = arbeitszeitenRAW.metaData.datenSindAktuell;
      //booleanDatenAktuell = true;
    }

    return arrZeilen;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);

  /**
   * bei jedem MA select wird der State aktualisiert
   */
  const handleChangeSelect = (value: any) => {
    setSelectedData(value);
  };

  // Umsetzen von Monats/Jahres Picker
  const handleChangeDate = (props: any) => {
    let momentAsDayJS = dayjs(props);

    const month = momentAsDayJS.toDate().getMonth() + 1; // +1, weil sonst 0 = Jan, 11 = Dez
    const year = momentAsDayJS.toDate().getFullYear();

    const startOfMonth = moment(year + "-" + month + "-15")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(year + "-" + month + "-15")
      .endOf("month")
      .format("YYYY-MM-DD");
    setStartDate(moment(startOfMonth).format());
    setEndDate(moment(endOfMonth).hour(0).format());
    calendarRef.current?.getApi().changeView("customMonth", "" + momentAsDayJS.format("YYYY-MM-DD"));
    //TODO: für Wochenansicht abfragen
    //calendarRef.current?.getApi().changeView("customWeek", "" + momentAsDayJS.format("YYYY-MM-DD"));
  };

  // TODO  schauen ob das schöner geht ohne die eslint disable
  let runOnceBoolean = true;
  // eslint-disable-next-line
  const runOnce = useMemo(() => {
    const dateForInitialChangeDate = new Date();
    let objectForInitialChangeDate = { _d: dateForInitialChangeDate };
    handleChangeDate(objectForInitialChangeDate);
    // eslint-disable-next-line
    runOnceBoolean = false;
    // eslint-disable-next-line
  }, [(runOnceBoolean = true)]);

  /**
   * Text fuer Tooltips generieren
   * @param event
   */
  const generateTooltipText = (event: EventImpl) => {
    let returnTooltipText = "<div style='margin: 8px'>";
    returnTooltipText += "" + event.extendedProps.tooltip;
    return returnTooltipText + "</div>";
  };

  /**
   * Contextmenu unterdruecken und eigenes anzeigen
   * @param eventGot
   * @param evt
   */
  const createContextMenu = (eventGot: EventImpl, evt: MouseEvent) => {
    evt.preventDefault();

    setDisplayModalMenuItems(createMenuForEvent(eventGot));
    // DIV wieder verschieben
    setDisplayModalMenuPosX(evt.x);
    setDisplayModalMenuPosY(evt.y);

    setDisplayModalMenu(true);
  };

  /**
   * Listener für Menüklick ausserhalb des Menüs
   */
  document.addEventListener("click", (evt) => {
    if (
      displayModalMenu &&
      !(
        evt.clientX >= displayModalMenuPosX &&
        evt.clientX <= displayModalMenuPosX + 450 &&
        evt.clientY >= displayModalMenuPosY &&
        evt.clientY <= displayModalMenuPosY + 350
      )
    )
      setDisplayModalMenu(false);
  });

  /**
   * Handler fuer Contextmenueintraege
   */
  const handleClickMenu = () => {
    setDisplayModalMenuPosX(-500);
    setDisplayModalMenuPosY(-500);
    setDisplayModalMenu(false);
  };

  /**
   * Simpler reload der Daten
   */
  const reloadAndResetAfterModal = () => {
    let start: string =
      "" + dayjs(startDate).format("YYYY-MM-DD") + "T00:00:" + dayjs(startDate).add(1, "second").format("ss") + ".000Z";
    setStartDate(start);
  };

  /**
   *
   * @param eventGot
   */
  const handleClickKorrektur = (eventGot: EventImpl) => {
    globalMemory.ma = eventGot.extendedProps.detailMA;
    globalMemory.modalTitle = "Korrektur der Überstunden";

    let korrektur: Dictionary = {};
    korrektur.korrekturDate = dayjs(startDate).format("MM. YYYY");
    korrektur.korrektur = eventGot.extendedProps.detailPM;
    korrektur.summeUeberstunden = eventGot.extendedProps.detailSummePM;
    setDetailDataKorrektur(korrektur);
    setDisplayFirstOpen(true);
    globalMemory.maid = eventGot.extendedProps.detailMAID;
    globalMemory.token = getWertFromlocalStorage("loginInfo", "userToken");
    globalMemory.modalAktDate = dayjs(startDate);
    globalMemory.modalFilialtoken = filialToken;

    handleClickMenu();
    setIsModalKorrekturVisible(true);
  };

  /**
   *
   * @param eventGot
   */
  const handleClickIstBearbeiten = (eventGot: EventImpl) => {
    if (!eventGot.title.includes("00:00") && eventGot.title.includes(":")) {
      /**
       * Bearbeiten Weg
       */
      globalMemory.modalTitle = "Ist-Arbeitszeit bearbeiten";
      globalMemory.ma = eventGot.extendedProps.detailMA;
      globalMemory.modalAktDate = dayjs(eventGot.start);

      setPausenVisible(eventGot.extendedProps.detailZeiten.ist.pausen.length);

      setDisplayFirstOpen(true);
      setDetailDataIstArbeitszeiten(eventGot);
      handleClickMenu();
      setIsModalIstZeitenVisible(true);
    } else {
      /**
       * Neuanlage Weg
       */
      globalMemory.modalTitle = "Ist-Arbeitszeit anlegen";
      globalMemory.ma = eventGot.extendedProps.detailMA;
      globalMemory.modalAktDate = dayjs(eventGot.start);
      setDisplayFirstOpen(true);
      setDetailDataIstArbeitszeiten(eventGot);
      handleClickMenu();
      setIsModalIstZeitenVisible(true);
    }
  };

  /**
   *
   * @param eventGot
   */
  const handleClickIstDelete = (eventGot: EventImpl) => {
    globalMemory.modalTitle = "Ist-Arbeitszeit löschen";
    globalMemory.confirmationModal.confirmationText = (
      <>
        Lösche er <br /> <b>mich jetzt!!!</b>
      </>
    );
    globalMemory.confirmationModal.confirmationMA = eventGot.extendedProps.detailMA;
    globalMemory.confirmationModal.confirmationDate = dayjs(eventGot.start).format("dd.MM.YYYY");
    globalMemory.confirmationModal.confirmationSubmitText = "Löschen";
    globalMemory.confirmationModal.confirmationCancelText = "Abbrechen";
    globalMemory.confirmationModal.confirmationSubmitCall = eventGot;
    handleClickMenu();
    setIsModalConfirmationVisible(true);
  };

  /**
   *
   */
  const handleDeleteIstZeit = () => {
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    let datum =
      dayjs(globalMemory.confirmationModal.confirmationSubmitCall.start).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    let maid = globalMemory.confirmationModal.confirmationSubmitCall.extendedProps.detailMAID;
    fetchData(getQuerydeleteIstArbeitszeit({ userToken, filialToken, maid, datum })).then(function (response) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.deleteIstArbeitszeit;
      checkQueryRespone(temp.data);
    });
  };

  /**
   *
   * @param values
   */
  const handleIstZeitSubmit = (values: any) => {
    //globalMemory.modalTitle = "Ist Arbeitszeit abschicken";
    globalMemory.confirmationModal.confirmationText = <>Bist du dir sicher?</>;

    globalMemory.confirmationModal.confirmationMA = globalMemory.ma;
    globalMemory.confirmationModal.confirmationDate = globalMemory.modalAktDate.format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.confirmationModal.confirmationSubmitText = "Absenden";
    globalMemory.confirmationModal.confirmationCancelText = "Abbrechen";
    setSubmitValues(values);
    handleClickMenu();
    setIsModalIstZeitenVisible(false);
    setIsModalConfirmationVisible(true);
  };

  /**
   *
   */
  const handleBearbeitenIstZeit = () => {
    let values: any = submitValues;
    setIsModalConfirmationVisible(false);

    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    let datum = globalMemory.modalAktDate.format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    let maid = detailDataIstArbeitszeiten.extendedProps.detailMAID;
    let zeitVon = values.ArbeitszeitAb.format("HH:mm");
    let zeitBis = values.ArbeitszeitBis.format("HH:mm");
    let pausen: APISetIstArbeitszeitPause[] = [];

    for (let i = 1; i <= 4; i++) {
      if (values["pause" + i + "Von"] != null) {
        let pause: APISetIstArbeitszeitPause = {
          pauseVon: values["pause" + i + "Von"].format("HH:mm"),
          pauseBis: values["pause" + i + "Bis"].format("HH:mm"),
        };
        if (pause.pauseVon !== pause.pauseBis) pausen.push(pause);
      }
    }

    fetchData(getQuerySetIstArbeitszeit({ userToken, filialToken, maid, datum, zeitVon, zeitBis, pausen })).then(
      function (response) {
        // TODO: Response ausgeben ob erfolgreich oder Fehler
        let temp = response.data?.data?.setIstArbeitszeit;
        checkQueryRespone(temp.data);
      }
    );
  };

  /**
   * Prüfen ob der gesendete Query Fehlerzurückmeldet und diese dann in einem Modal anzeigen
   */
  const checkQueryRespone = (data: APIErfolgRueckmeldung) => {
    if (data === null) {
      globalMemory.modalTitle = "Kritischer Fehler";
      globalMemory.informationModal.informationText = "Datenobjekt ist leer.  Bitte melde dich beim Support!";
      globalMemory.informationModal.informationSubmitText = "Schließen";
      setIsModalInformationVisible(true);
    } else if (!data.erfolgreich) {
      globalMemory.modalTitle = data.ueberschrift;
      globalMemory.informationModal.informationText = data.infotext;
      globalMemory.informationModal.informationSubmitText = "Schließen";
      setIsModalInformationVisible(true);
    } else if (data.erfolgreich) {
      reloadAndResetAfterModal();
    } else {
      globalMemory.modalTitle = "Kritischer Fehler";
      globalMemory.informationModal.informationText = "Hier sollten wir nie landen. Bitte melde dich beim Support!";
      globalMemory.informationModal.informationSubmitText = "Schließen";
      setIsModalInformationVisible(true);
    }
  };

  /**
   *
   */
  const handleSubmitConfirmationModal = () => {
    setIsModalConfirmationVisible(false);
    if (globalMemory.modalTitle.includes("Ist Arbeitszeit löschen")) {
      handleDeleteIstZeit();
    } else {
      handleBearbeitenIstZeit();
    }
  };

  /**
   *
   */
  const handleCancelConfirmationModal = () => {
    setIsModalConfirmationVisible(false);
  };

  /**
   * Contextmenueintraege erzeugen
   * @param eventGot
   */
  const createMenuForEvent = (eventGot: EventImpl) => {
    let aktMA = eventGot.extendedProps.detailMA;
    let items: ItemType[] = [];
    if (!eventGot.title.includes("00:00") && eventGot.title.includes(":")) {
      /*       items.push({
         key: "01",
         label: <div>Ist Arbeitszeit bearbeiten ({dayjs(eventGot.start).format("DD.MM.YYYY")}) </div>,
         onClick: function () {
           handleClickIstBearbeiten(eventGot);
         },
         icon: <EditTwoTone />,
       });
       items.push({
         key: "01",
         label: <div>Ist Arbeitszeit löschen ({dayjs(eventGot.start).format("DD.MM.YYYY")})</div>,
         onClick: function () {
           handleClickIstDelete(eventGot);
         },
         icon: <EditTwoTone />,
       });
     } else {
       items.push({
         key: "01",
         label: <div>Ist Arbeitszeit eintragen ({dayjs(eventGot.start).format("DD.MM.YYYY")})</div>,
         onClick: function () {
           handleClickIstBearbeiten(eventGot);
         },
         icon: <EditTwoTone />,
       });*/
    }
    /*
    items.push({
      key: "01",
      label: <div>Korrektur Überstunden {aktMA} </div>,
      onClick: function () {
        handleClickKorrektur(eventGot);
      },
      icon: <EditTwoTone />,
    });
*/
    items.push({
      key: "01",
      label: <div>Kein Menu vorhanden </div>,
      disabled: true,
      onClick: function () {
        handleClickKorrektur(eventGot);
      },
      icon: <EditTwoTone />,
    });

    if (!isCompanyAdministrator()) {
      items.splice(5, 2);
    }

    return items;
  };

  /**
   * Listener erzeugen um Contextmenu zu ueberschreiben
   * @param eventGot
   */
  const handleEventRender = (eventGot: EventMountArg) => {
    eventGot.el.addEventListener("contextmenu", (evt) => createContextMenu(eventGot.event, evt));
  };

  /**
   * Dynamische Feste Tabellenspalten
   */
  let arrInitialColumns: {}[] = useMemo(() => {
    let returnArr: {}[] = [];
    const arrInitial: ColSpec[] = [
      { field: "title", headerContent: "", width: 100 },
      {
        field: "headerPN",
        headerContent: "PN",
        width: 25,
      },
      {
        field: "headerSoll",
        headerContent: "Soll",
        width: 40,
      },
      {
        field: "headerIst",
        headerContent: "Ist",
        width: 40,
      },
      {
        field: "headerPlusMinus",
        headerContent: "+/-",
        width: 40,
      },
      {
        field: "headerPlusMinusGesamt",
        headerContent: "∑ +/-",
        width: 40,
      },
      {
        field: "headerUrlaub",
        headerContent: elementIconBeach,
        width: 30,
      },
      {
        field: "headerUrlaubSumme",
        headerContent: (
          <div style={{ display: "flex", alignItems: "left" }}>
            <span>Rest-</span>
            {elementIconBeach}
          </div>
        ),
        width: 40,
      },
      {
        field: "headerKrank",
        headerContent: <div style={{ display: "flex", alignItems: "left" }}>{elementIconTherm}</div>,
        width: 30,
      },
      {
        field: "headerKrankSumme",
        headerContent: (
          <div style={{ display: "flex", alignItems: "left" }}>
            <span>Σ</span>
            {elementIconTherm}
          </div>
        ),
        width: 30,
      },
    ];

    for (const objColSpec of arrInitial) {
      if (selectedData.includes("Adrian")) {
      } else {
        returnArr.push(objColSpec);
      }
    }
    return returnArr;
  }, [selectedData]);

  /**
   *
   */
  const arrMitarbeiter = useMemo(() => {
    let returnArr: {}[] = [];

    for (let arbeitszeitenRAWKey in arbeitszeitenRAW.tabZeilen) {
      let aktSpalten = arbeitszeitenRAW.tabZeilen[arbeitszeitenRAWKey].spalten;

      if (selectedData.length === 0 || (selectedData.length !== 0 && selectedData.includes(aktSpalten[0]))) {
        let aktObj = {
          id: "" + aktSpalten[0] + aktSpalten[1],
          title: "" + aktSpalten[0],
          headerPN: "" + aktSpalten[1],
          headerSoll: "" + aktSpalten[aktSpalten.length - 9],
          headerIst: "" + aktSpalten[aktSpalten.length - 8],
          headerPlusMinus: "" + aktSpalten[aktSpalten.length - 7],
          headerPlusMinusGesamt: "" + aktSpalten[aktSpalten.length - 6],
          headerUrlaub: "" + aktSpalten[aktSpalten.length - 5],
          headerUrlaubSumme: "" + aktSpalten[aktSpalten.length - 4],
          headerKrank: "" + aktSpalten[aktSpalten.length - 3],
          headerKrankSumme: "" + aktSpalten[aktSpalten.length - 2],
        };
        returnArr.push(aktObj);
      }
    }

    return returnArr;
  }, [arbeitszeitenRAW, selectedData, arrInitialColumns]);

  /**
   * Events für fullcalendar generieren
   *
   */
  const arrEvents: {}[] = useMemo(() => {
    let returnArr: {}[] = [];

    for (let arbeitszeitenRAWKey in arbeitszeitenRAW.tabZeilen) {
      let aktDateEvent = moment(startDate);
      let aktSpalten = arbeitszeitenRAW.tabZeilen[arbeitszeitenRAWKey].spalten;
      let aktSpaltenProps = arbeitszeitenRAW.tabZeilen[arbeitszeitenRAWKey].spaltenProps;
      for (let i = 2; i <= aktSpalten.length - 7; i++) {
        let aktDay = "01";
        if (i - 1 < 10) aktDay = "0" + (i - 1);
        else aktDay = "" + (i - 1);
        let actColor;
        if (aktSpalten[i].includes("W")) actColor = colorWochenende;
        else if (aktSpalten[i].includes("K")) actColor = colorKrankheitstag;
        else if (aktSpalten[i].includes("F")) actColor = colorFeiertag;
        else if (aktSpalten[i].includes("U")) actColor = colorUrlaubstag;
        else if (aktSpalten[i].includes("K")) actColor = colorKrankheitstag;
        else actColor = colorArbeitstag;
        let aktTooltipText = "Keine Arbeitszeit";
        if (aktSpaltenProps[i] != "") {
          aktTooltipText = aktSpaltenProps[i];
        }

        returnArr.push({
          title: "" + aktSpalten[i],
          start: "" + aktDateEvent.format("YYYY-MM") + "-" + aktDay,
          resourceId: "" + aktSpalten[0] + aktSpalten[1],
          color: actColor,
          tooltip: "" + aktTooltipText,
          detailMA: "" + aktSpalten[0],
          detailMAID: "" + aktSpalten[aktSpalten.length - 1],
          detailSummePM: aktSpalten[aktSpalten.length - 6],
          detailPM: aktSpalten[aktSpalten.length - 7],
          detailZeiten: arbeitszeitenRAW.tabZeilen[arbeitszeitenRAWKey].spaltenObjs[i],
        });
      }
    }

    return returnArr;
  }, [arbeitszeitenRAW, arrInitialColumns]);

  /**
   * Generate Static Columns for fullcalendar
   */
  function arrStatsColumns() {
    let arrInitial: ColSpec[] = arrInitialColumns;

    return arrInitial;
  }

  if (!booleanDatenAktuell) {
    return (
      <Content style={{ alignSelf: "center" }}>
        <div className={style.headline} style={{ textAlign: "center" }}>
          <Title level={2}>Daten sind nicht aktuell!</Title>
          <Result
            title="Bitte erneut auf die Filiale klicken"
            icon={<img src={iconDatenFehlen} />}
            extra={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>,
            ]}
          />
        </div>
      </Content>
    );
  }

  if (type) {
    // @ts-ignore
    return (
      <Content className="global-layout-background global-content">
        <Spin tip="Loading" size="large" spinning={isLoading}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>{type === "all" ? "Alle Filialen" : alias}</Breadcrumb.Item>
          </Breadcrumb>
          <Title> Arbeitszeitnachweis</Title>
          <Row justify="end" style={{ position: "absolute", right: 0, top: 0, marginRight: "5px" }}>
            <Button
              type="primary"
              onClick={() =>
                exportToCSV(
                  "Ist-Zeiten Export " +
                    moment(startDate).format("DD.MM.YYYY") +
                    " - " +
                    moment(endDate).format("DD.MM.YYYY") +
                    ".csv",
                  outputDataCSV
                )
              }
            >
              Daten exportieren
            </Button>
          </Row>
          <StyleWrapper style={{ marginRight: "5px" }}>
            <FullCalendar
              height={"auto"}
              plugins={[resourceTimelinePlugin, interactionPlugin]}
              initialView="customMonth"
              weekends={true}
              locale={"de"}
              handleWindowResize={true}
              firstDay={1}
              schedulerLicenseKey="0143926529-fcs-1689324442"
              views={{
                customWeek: {
                  type: "resourceTimeline",
                  duration: { weeks: 1 },
                  slotDuration: { days: 1 },
                  buttonText: "Wochenansicht",
                },
                customMonth: {
                  titleFormat: { year: "numeric", month: "2-digit", week: "long", day: "2-digit" },
                  titleRangeSeparator: " - ",
                  type: "resourceTimeline",
                  duration: { months: 1 },
                  slotDuration: { days: 1 },
                  buttonText: "Monatsansicht",
                },
              }}
              initialDate={"" + startDate} //startDate
              nowIndicator={false}
              headerToolbar={{
                left: "",
                center: "title",
                right: "",
              }}
              resourceAreaColumns={arrStatsColumns()}
              stickyFooterScrollbar={true}
              buttonText={{ today: "Heute" }}
              businessHours={[{ daysOfWeek: [1, 2, 3, 4, 5] }]}
              slotLabelFormat={[{ month: "short", year: "numeric" }, { day: "numeric" }]}
              resourceAreaWidth={660}
              slotMinWidth={40}
              expandRows={true}
              eventOrder={"order"}
              contentHeight={800}
              resources={arrMitarbeiter}
              events={arrEvents}
              resourcesInitiallyExpanded={false}
              ref={calendarRef}
              eventDidMount={handleEventRender}
              eventMouseEnter={function (info) {
                tippy(info.el, {
                  content: "" + generateTooltipText(info.event),
                  placement: "top",
                  interactive: false,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              dateClick={function () {}}
              eventClick={function (arg) {
                //handleClickIstBearbeiten(arg.event);
              }}
            ></FullCalendar>
          </StyleWrapper>
          <Row justify="start" style={{ marginTop: "8px", marginLeft: "8px" }}>
            <Col lg={6} xl={5} xxl={3}>
              <Form.Item> Mitarbeiter:&nbsp;</Form.Item>
            </Col>
            <Col lg={9} xl={6} xxl={5}>
              <div>
                <Select
                  mode="multiple"
                  allowClear={true}
                  style={{
                    marginBottom: "0px",
                    marginLeft: "8px",
                    width: "450px",
                  }}
                  placeholder="Bitte wählen Sie"
                  onChange={handleChangeSelect}
                >
                  {selectData}
                </Select>
              </div>
            </Col>
          </Row>
          <Row justify="start" style={{ marginTop: "-15px" }}>
            <IWMonthPicker
              onClick={handleChangeDate}
              range="month"
              aktToken={aktFilialToken}
              defaultDate={dayjs(startDate)}
            />
          </Row>
        </Spin>
        {/*-------------------------------------------------------------*/}
        {/*-------------------- Informationmodal-------------------*/}
        {/*-------------------------------------------------------------*/}
        <IWInformationModal
          displayModal={isModalInformationVisible}
          closeAction={setIsModalInformationVisible}
        ></IWInformationModal>

        {/*-------------------------------------------------------------*/}
        {/*-------------------- Confirmationmodal-------------------*/}
        {/*-------------------------------------------------------------*/}
        <IWConfirmationModal
          displayModal={isModalConfirmationVisible}
          closeAction={handleCancelConfirmationModal}
          okAction={handleSubmitConfirmationModal}
        ></IWConfirmationModal>

        {/*-------------------------------------------------------------*/}
        {/*-------------------- Korrekturmodal-------------------*/}
        {/*-------------------------------------------------------------*/}
        <IWKorrektur closeAction={setIsModalKorrekturVisible}></IWKorrektur>

        {/*-------------------------------------------------------------*/}
        {/*---------------------Ist Arbeitszeiten modal-------------------*/}
        {/*-------------------------------------------------------------*/}
        <IWIstArbeitszeitenModal
          submitAction={handleIstZeitSubmit}
          strVon={
            detailDataIstArbeitszeiten.extendedProps != undefined &&
            detailDataIstArbeitszeiten.extendedProps.detailZeiten.ist.von.length != 0
              ? detailDataIstArbeitszeiten.extendedProps.detailZeiten.ist.von
              : "" + searchFilialtokenInArray(globalMemory.oeffnungszeitenStammdaten, filialToken[0])?.oeffneUhrzeit
          }
          strBis={
            detailDataIstArbeitszeiten.extendedProps != undefined &&
            detailDataIstArbeitszeiten.extendedProps.detailZeiten.ist.bis.length != 0
              ? detailDataIstArbeitszeiten.extendedProps.detailZeiten.ist.bis
              : "" + searchFilialtokenInArray(globalMemory.oeffnungszeitenStammdaten, filialToken[0])?.schliesseUhrzeit
          }
          arrPausen={
            detailDataIstArbeitszeiten.extendedProps != null
              ? detailDataIstArbeitszeiten.extendedProps.detailZeiten.ist.pausen
              : []
          }
        ></IWIstArbeitszeitenModal>

        {/*-------------------------------------------------------------*/}
        {/*-------------------- Kontextmenu ----------------------------*/}
        {/*-------------------------------------------------------------*/}
        <Dropdown open={displayModalMenu} menu={{ items: displayModalMenuItems }} trigger={["click"]} autoFocus={true}>
          <p
            style={{
              position: "absolute",
              left: displayModalMenuPosX - 20,
              top: displayModalMenuPosY - 20,
              width: 1,
              height: 1,
            }}
          ></p>
        </Dropdown>
      </Content>
    );
  }

  return (
    <Content className="global-layout-background global-content">
      <div className={style.headline}>
        <Title level={2}>Arbeitszeitnachweis</Title>
        <Result
          title="Noch kein Verbund / Filiale ausgewählt"
          extra={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <text style={{ textAlign: "left" }}>
                <div>1. Bitte zunächst links eine Auswahl treffen (Alle Filialen, Verbund oder einzelne Filiale)</div>
                <div>2. Betrachtungszeitraum anpassen</div>
              </text>
            </div>,
          ]}
        />
      </div>
    </Content>
  );
};

export default React.memo(Arbeitszeiten);
