import { useQuery } from "react-query";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { APIStatistik } from "../types/apiTypes";
import { APPIDENT } from "../constants";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
  filialToken: string[];
  startDate: string;
  endDate: string;
  statistikIDQuery: number;
}

const getQuery = ({ userToken, filialToken, startDate, endDate, statistikIDQuery }: QueryParams) => ({
  query: `query {
        getStatistik(      
          appIdent: ${APPIDENT},    
          version: "${pkg.version}",    
          userToken: "${userToken}",
          filialToken: ${JSON.stringify(filialToken)},
          statistikID:${statistikIDQuery},
          datum1: "${startDate}",
          datum2: "${endDate}") {
          header {
            success,
            errorCode,
            errorText
          },
          data {
            textHeader,
            tabHeader {
            text,
            suchbar,
            sortierbar,
            stdSortierung,
            typ,
            ausrichtung,
            breiteProzent,
            sichtbar,
            statisch
            },
            tabZeilen {
              spalten
            }
          }
        }
                }`,
});

export const useStatistiken = (queryParams: QueryParams, queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  let queryObject = getQuery(queryParams);
  let { isLoading, error, data } = useQuery(
    ["statistik", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const statistik: APIStatistik = data?.data?.data?.getStatistik?.data ?? [];

  return { isLoading, error, statistik };
};
