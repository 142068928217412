import create from "zustand";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../constants";

export interface StatistikStateInterface {
  startDate: string;
  setStartDate: (searchText: string) => void;
  endDate: string;
  setEndDate: (endDate: string) => void;
  selectedData: string;
  setSelectedData: (selectedData: string) => void;
  selectedDataSecond: string;
  setSelectedDataSecond: (selectedDataSecond: string) => void;
  labelSelect: string;
  setLabelSelect: (labelSelect: string) => void;
  labelSelectSecond: string;
  setLabelSelectSecond: (labelSelectSecond: string) => void;
  showSelect: number;
  setShowSelect: (showSelect: number) => void;
  showSelectSecond: number;
  setShowSelectSecond: (showSelectSecond: number) => void;
  chartDataIndex: number;
  setChartDataIndex: (chartDataIndex: number) => void;
  chartDataIndexManual: boolean;
  setChartDataIndexManual: (chartDataIndexManual: boolean) => void;
  chartTitle: string;
  setChartTitle: (chartTitle: string) => void;
  aktStatistik: string;
  setAktStatistik: (aktStatistik: string) => void;
}

export const useStatistikStore = create<StatistikStateInterface>()((set) => ({
  aktStatistik: "",
  chartDataIndex: -1,
  chartDataIndexManual: false,
  chartTitle: "",
  endDate: DEFAULT_DATEPICKER_END_MOMENT.format(),
  labelSelect: "",
  labelSelectSecond: "",
  selectedData: "",
  selectedDataSecond: "",
  setAktStatistik: (aktStatistik: string) => set({ aktStatistik }),
  setChartDataIndex: (chartDataIndex: number) => set({ chartDataIndex }),
  setChartDataIndexManual: (chartDataIndexManual: boolean) => set({ chartDataIndexManual }),
  setChartTitle: (chartTitle: string) => set({ chartTitle }),
  setEndDate: (endDate: string) => set({ endDate }),
  setLabelSelect: (labelSelect: string) => set({ labelSelect }),
  setLabelSelectSecond: (labelSelectSecond: string) => set({ labelSelectSecond }),
  setSelectedData: (selectedData: string) => set({ selectedData }),
  setSelectedDataSecond: (selectedDataSecond: string) => set({ selectedDataSecond }),
  setShowSelect: (showSelect: number) => set({ showSelect }),
  setShowSelectSecond: (showSelectSecond: number) => set({ showSelectSecond }),
  setStartDate: (startDate: string) => set({ startDate }),
  showSelect: -1,
  showSelectSecond: -1,
  startDate: DEFAULT_DATEPICKER_START_MOMENT.format(),
}));
