import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import { Card, Layout, Progress, Result } from "antd";

import NavTop from "../pages/NavTop/NavTop";
import NavLeft from "../pages/NavLeft/NavLeft";
import Login from "../pages/Login/Login";
import Statistik from "../pages/Statistiken/Statistik";

import Home from "../pages/Home/Home";
import Datev from "../pages/Datev/Datev";
import Arbeitszeiten from "../pages/Arbeitszeiten/Arbeitszeiten";

import style from "./App.module.css";
import { QueryClient, QueryClientProvider } from "react-query";
import UserManager from "../pages/UserManager/UserManager";

import logo from "../images/connect.svg";
import { Content } from "antd/es/layout/layout";
import SollArbeitszeiten from "../pages/SollArbeitszeiten/SollArbeitszeiten";

import Planer from "../pages/Planer/Planer";
import {
  GENERICTYPE_PARAMETER_ACTIVEMODUL,
  GENERICTYPE_PARAMETER_LOCALDB,
  GENERICTYPE_PARAMETER_MSM,
} from "../../types/genericTypes";
import { APILoginInfo } from "../../types/apiTypes";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCopy,
  faInfoCircle,
  faGears,
  faArrowsV,
  faArrowsH,
  faClock,
  faUmbrellaBeach,
  faFaceThermometer,
  faUser,
  faCalendar,
  faArrowRightArrowLeft,
  faArrowRight,
  faPenToSquare,
  faEye,
  faBookCopy,
  faFileImport,
} from "@fortawesome/pro-light-svg-icons";

import { useAppStore } from "../../store/zustandAppStore";

library.add(
  faCopy,
  faInfoCircle,
  faGears,
  faArrowsV,
  faArrowsH,
  faClock,
  faUmbrellaBeach,
  faFaceThermometer,
  faUser,
  faCalendar,
  faArrowRightArrowLeft,
  faArrowRight,
  faPenToSquare,
  faEye,
  faBookCopy,
  faFileImport
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

/**
 * Call
 * connect.exe ipAdresseServer isMSM licenseCodeServer isLocalDB activeModules activeMAID optActiveModuleSubID optAdditionalParameters
 * "e-cut Connect.exe" 127.0.0.1 0 1234-1234-1234-1234-1234 0 "dienstplan" "{\"benutzername\": \"ew@messe\",\"userToken\": \"-1;ADMIN\",\"rolle\": \"ADMIN\", \"name\": \"Wickler\",\"vorname\": \"Ent\",\"email\": \"\"}" "1234567890" optAdditionalParameters
 *  ipAdresseServer = "127.0.0.1";
 *  isMSM = "0"; // 0..e-cut; 1..MSM
 *  licenseCodeServer = "1234-1234-1234-1234-1234";
 *  isLocalDB = "0"; // 0..local; 1..Server
 *  activeModules = "connect";
 *  activeMAObject = {}; JSON-Object APILoginInfo
 *  connect =  normales Connect
 *  dienstplan
 *  arbeitszeitnachweis
 *  statistik
 *  forecast
 *  einstellungen
 *
 * optActiveModuleSubID = "1"; // ID für Object innerhalb des gewählten Modules Bsp. StatistikID bei Statistiken
 * optAdditionalParameters = "{}"; // JSON Object
 */

/** Test JSON User
 * "{\"benutzername\": \"ew@messe\",\"userToken\": \"36n2zBp3bbkcMJVQAwFJevX6gTpQuHKhTGACP3twndRQP2FC6p3SPDFpcpkx8HSL\",\"rolle\": \"ADMIN\", \"name\": \"Wickler\",\"vorname\": \"Ent\",\"email\": \"\",}",
 */

export let cmdParameter = {
  ipAdresseServer: "127.0.0.1",
  isMSM: GENERICTYPE_PARAMETER_MSM.GENERICTYPE_PARAMETER_MSM_CONNECT,
  licenseCodeServer: "1234-1234-1234-1234-1234",
  isLocalDB: GENERICTYPE_PARAMETER_LOCALDB.GENERICTYPE_PARAMETER_LOCALDB_LOCAL,
  activeModules: GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT,
  activeMAID: "0",
  optActiveModuleSubID: "",
  optAdditionalParameters: "{}",
};

/**
 * ATOM Konfiguration
 */

const App = () => {
  document.documentElement.lang = "de";

  const {
    isOffline,
    setIsOffline,
    isServerDown,
    loggedIn,
    setLoggedIn,
    loggedInForModule,
    setLoggedInForModule,
    setToggled,
    isNavLeftVisible,
    isConnectLoading,
    setIsConnectLoading,
    connectLoadingStatus,
    setConnectLoadingStatus,
    isNoModulLoginDone,
    setIsNoModulLoginDone,
    isModulLoginDone,
    setIsModulLoginDone,
    runningLoading,
    setRunningLoading,
  } = useAppStore();

  // Übergebene Parameter setzen (ts-ignore notwendig, da electronAPI nicht vorhanden ist, sondern über preload.ts dynamisch eingeladen wird)
  // @ts-ignore
  if (window.electronAPI) {
    // @ts-ignore
    window.electronAPI.handleNotifyNewArgV((event, value) => {
      if (value[1]) {
        cmdParameter.ipAdresseServer = value[1];
      }
      if (value[2] && value[2] == "1") {
        cmdParameter.isMSM = GENERICTYPE_PARAMETER_MSM.GENERICTYPE_PARAMETER_MSM_MSM;
      }
      if (value[3]) {
        cmdParameter.licenseCodeServer = value[3];
      }
      if (value[4] && value[4] == GENERICTYPE_PARAMETER_LOCALDB.GENERICTYPE_PARAMETER_LOCALDB_SERVER) {
        cmdParameter.isLocalDB = GENERICTYPE_PARAMETER_LOCALDB.GENERICTYPE_PARAMETER_LOCALDB_SERVER;
      }
      if (value[5]) {
        cmdParameter.activeModules = value[5];
      }
      if (value[6]) {
        cmdParameter.activeMAID = value[6];
      }
      if (value[7]) {
        cmdParameter.optActiveModuleSubID = value[7];
      }
      if (value[8]) {
        cmdParameter.optAdditionalParameters = value[8];
      }
      if (cmdParameter.activeModules != GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT) {
        cmdParameter.optActiveModuleSubID = "1234567890";
      }
    });
  }

  let loggedInLS: boolean = false;

  if (cmdParameter.activeModules !== GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT) {
    if (cmdParameter.activeMAID != "0" && !isModulLoginDone) {
      try {
        let actMA: APILoginInfo = JSON.parse(cmdParameter.activeMAID);
        if (actMA.benutzername !== null && actMA.benutzername !== undefined && actMA.benutzername !== "") {
          localStorage.setItem("loginInfo", JSON.stringify(actMA));
          setLoggedInForModule(true);
          setLoggedIn(true);
        } else {
          setLoggedInForModule(false);
          setToggled(false);
        }
      } catch (e) {
        setLoggedInForModule(false);
        setToggled(false);
      }
      setIsModulLoginDone(true);
    }
  } else {
    if (!isNoModulLoginDone) {
      loggedInLS = !!(localStorage.loginInfo && JSON.parse(localStorage.loginInfo).benutzername);
      setLoggedIn(loggedInLS);
      setIsNoModulLoginDone(true);
      setLoggedInForModule(true);
    }
  }

  const conicColors = {
    "0%": "#8EB8F0",
    "100%": "#8085D9",
  };
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const loadingWait = async () => {
    if (isConnectLoading && !runningLoading) {
      setRunningLoading(true);
      for (let i = 0; i < 100; i++) {
        await delay(50);
        setConnectLoadingStatus(i);
      }
      setIsConnectLoading(false);
    }
  };

  loadingWait().then(() => null);

  const onOffline = () => {
    setIsOffline(true);
  };

  const onOnline = () => {
    setIsOffline(false);
  };

  const handleContextMenu = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  function componentWithProps(Component: any, props: any) {
    return function (matchProps: any) {
      return <Component {...props} {...matchProps} />;
    };
  }

  /**
   * Optional React Profiler function
   * @param id
   * @param phase
   * @param actualDuration
   * @param baseDuration
   * @param startTime
   * @param commitTime
   */
  // function onRender(id: any, phase: any, actualDuration: any, baseDuration: any, startTime: any, commitTime: any) {}

  // @ts-ignore
  return (
    /**<Profiler id="App" onRender={onRender}>*/
    <QueryClientProvider client={queryClient}>
      <div className={"notranslate"}>
        {!isOffline ? (
          <>
            {!isServerDown ? (
              <Layout className={style.rootLayout} id="rootLayout">
                {loggedInForModule ? (
                  <>
                    {loggedIn ? (
                      <>
                        <Router>
                          <>
                            <NavTop isConnectLoading={isConnectLoading} />
                            {!isConnectLoading ? (
                              <>
                                <Layout>
                                  <NavLeft />

                                  <Layout className={style.contentLayout}>
                                    <Switch>
                                      <Route exact path="/home" component={Home} />
                                      <Route exact path="/login" component={Login} />
                                      <Route exact path="/arbeitszeiten/:type?/:token?" component={Arbeitszeiten} />
                                      {/* <Route exact path="/filial_manager" component={FilialManager} />*/}
                                      <Route exact path="/datev/:type?/:token?" component={Datev} />
                                      <Route
                                        exact
                                        path="/statistik/:statistikID/:navType?/:navToken?"
                                        component={Statistik}
                                      />
                                      <Route exact path="/user_manager" component={UserManager} />
                                      <Route
                                        exact
                                        path="/sollarbeitszeiten/:type?/:token?"
                                        component={componentWithProps(SollArbeitszeiten, {
                                          isNavLeftVisible: isNavLeftVisible,
                                        })}
                                      />
                                      <Route
                                        exact
                                        path="/planer/:token?"
                                        component={componentWithProps(Planer, { isNavLeftVisible: isNavLeftVisible })}
                                      />
                                      {cmdParameter.activeModules ===
                                      GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_DIENSTPLAN ? (
                                        <Redirect
                                          to={"/sollarbeitszeiten/filiale/" + cmdParameter.optActiveModuleSubID}
                                          from="/"
                                        />
                                      ) : (
                                        <>
                                          {cmdParameter.activeModules ===
                                          GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_ARBEITSZEITNACHWEIS ? (
                                            <Redirect
                                              to={"/arbeitszeiten/filiale/" + cmdParameter.optActiveModuleSubID}
                                              from="/"
                                            />
                                          ) : (
                                            <Redirect to="/home" from="/" />
                                          )}
                                        </>
                                      )}
                                    </Switch>{" "}
                                  </Layout>
                                </Layout>
                              </>
                            ) : (
                              <Card title="Lade e-cut" style={{ alignSelf: "center", marginTop: 200, width: 300 }}>
                                <Progress
                                  percent={connectLoadingStatus}
                                  showInfo={true}
                                  type="line"
                                  strokeColor={conicColors}
                                />
                              </Card>
                            )}
                          </>
                        </Router>
                      </>
                    ) : (
                      <Login />
                    )}
                  </>
                ) : (
                  <Content style={{ textAlign: "center", height: "50%", width: "100%" }}>
                    <div style={{ height: "25%", width: "100%" }}></div>
                    <div style={{ height: "5%", width: "100%" }}></div>
                    <Result
                      status="404"
                      title="Ups, da ist etwas schief gelaufen!"
                      extra={[
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <text style={{ textAlign: "center" }}>
                            <div>
                              Leider fehlen dir die Berechtigungen auf dieses Modul zuzugreifen oder etwas ist schief
                              gelaufen.
                            </div>
                          </text>
                        </div>,
                      ]}
                    />
                  </Content>
                )}
              </Layout>
            ) : (
              <Content style={{ textAlign: "center", height: "50%", width: "100%" }}>
                <div style={{ height: "25%", width: "100%" }}></div>
                <img src={logo} alt="e-cut Connect" style={{ width: "25%" }} />
                <div style={{ height: "5%", width: "100%" }}></div>
                <Result
                  status="404"
                  title="Ups, der e-cut Server hat gerade keine Lust!"
                  extra={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <text style={{ textAlign: "center" }}>
                        <div>
                          Sobald wieder eine Verbindung zum e-cut Server besteht, wird sich diese Seite selbstständig
                          aktualisieren.
                        </div>
                      </text>
                    </div>,
                  ]}
                />
              </Content>
            )}
          </>
        ) : (
          <Content style={{ textAlign: "center", height: "50%", width: "100%" }}>
            <div style={{ height: "25%", width: "100%" }}></div>
            <img src={logo} alt="e-cut Connect" style={{ width: "25%" }} />
            <div style={{ height: "5%", width: "100%" }}></div>
            <Result
              status="500"
              title="Es besteht zur Zeit keine Verbindung zum Internet!"
              style={{ width: "100%" }}
              extra={[
                <div>
                  <text>
                    <div>
                      Sobald eine Verbindung zum Internet besteht, wird sich diese Seite selbstständig aktualisieren.
                    </div>
                  </text>
                </div>,
              ]}
            />
          </Content>
        )}
      </div>
    </QueryClientProvider>
    /**</Profiler>*/
  );
};

export default App;
