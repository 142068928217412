import { APPIDENT } from "../constants";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { useQuery } from "react-query";
import { APIGetSollArbeitszeiten } from "../types/apiTypes";
const pkg = require("../../package.json");

export interface QueryParams {
  userToken: string;
  filialToken: string[];
  startDate: string;
  endDate: string;
}
export interface QueryParamsSingle {
  userToken: string;
  filialid: string[];
  startDate: string;
  endDate: string;
  maid: number;
}
export const getQueryGetSollArbeotszeitenSingleMA = ({
  userToken,
  filialid,
  startDate,
  endDate,
  maid,
}: QueryParamsSingle) => ({
  query: `query {
      getSollArbeitszeiten(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: ${JSON.stringify(filialid)},
        datum1: "${startDate}",
        datum2: "${endDate}",
        maid:${maid}) {
        header {
                  success,
                  errorCode,
                  errorText
              },
              data {
                  maid,
                  sollAZeitFilialen {
                      filialToken,
                      readOnly,
                      sollAZeitFilialTage {
                          datum,
                          arbeitszeit {
                              id,
                              datumVon,
                              datumBis,
                              arbeitszeit,
                              uhrzeitVon,
                              uhrzeitBis,
                              pause,
                              pauseFix,
                              pause1Von,
                              pause1Bis,
                              pause2Von,
                              pause2Bis,
                              turnusTyp,
                              bemerkung
                          },
                          abwesenheit {
                              id,
                              abwesendTyp,
                              datumVon,
                              datumBis,
                              uhrzeitVon,
                              uhrzeitBis,
                              turnusTyp
                              halberTag,
                              rest,
                              bemerkung
                          }
                          anzeigeZeitTyp
                      },
                      sollAZeitSumme {
                          arbeitszeit,
                          urlaub,
                          krank,
                          schule,
                          sonstiges,
                          feiertag,
                          wochenende,
                          ruhetag
                      }
                  }
                  sollAZeitMATage {
                      datum,
                      sollAZeitSumme {
                          arbeitszeit,
                          urlaub,
                          krank,
                          schule,
                          sonstiges,
                          feiertag,
                          wochenende,
                          ruhetag
                      }
                      anzeigeZeitTyp
                  },
                  sollAZeitSumme {
                      arbeitszeit,
                      urlaub,
                      krank
                  }
              }
          }
      }
  `,
});

export const getQueryGetSollArbeotszeiten = ({ userToken, filialToken, startDate, endDate }: QueryParams) => ({
  query: `query {
      getSollArbeitszeiten(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: ${JSON.stringify(filialToken)},
        datum1: "${startDate}",
        datum2: "${endDate}") {
        header {
                  success,
                  errorCode,
                  errorText
              },
              data {
                  maid,
                  sollAZeitFilialen {
                      filialToken,
                      readOnly,
                      sollAZeitFilialTage {
                          datum,
                          anzeigeZeitTyp,
                          anzeigeZeitTypAktiv,
                          arbeitszeit {
                              id,
                              datumVon,
                              datumBis,
                              arbeitszeit,
                              uhrzeitVon,
                              uhrzeitBis,
                              pause,
                              pauseFix,
                              pause1Von,
                              pause1Bis,
                              pause2Von,
                              pause2Bis,
                              turnusTyp,
                              bemerkung
                          },
                          abwesenheit {
                              id,
                              abwesendTyp,
                              datumVon,
                              datumBis,
                              uhrzeitVon,
                              uhrzeitBis,
                              turnusTyp
                              halberTag,
                              rest,
                              bemerkung
                          }
                          anzeigeZeitTyp
                      },
                      sollAZeitSumme {
                          arbeitszeit,
                          urlaub,
                          krank,
                          schule,
                          sonstiges,
                          feiertag,
                          wochenende,
                          ruhetag
                      }
                  }
                  sollAZeitMATage {
                      datum,
                      anzeigeZeitTyp,
                      anzeigeZeitTypAktiv,
                      sollAZeitSumme {
                          arbeitszeit,
                          urlaub,
                          krank,
                          schule,
                          sonstiges,
                          feiertag,
                          wochenende,
                          ruhetag
                      }
                      anzeigeZeitTyp
                  },
                  sollAZeitSumme {
                      arbeitszeit,
                      urlaub,
                      krank
                  }
              }
          }
      }
  `,
});

export const useSollArbeitszeiten = (queryParams: QueryParams, queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  let queryObject = getQueryGetSollArbeotszeiten(queryParams);
  let { isLoading, error, data } = useQuery(
    ["sollarbeitszeiten", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const sollarbeitszeiten: APIGetSollArbeitszeiten[] = data?.data?.data?.getSollArbeitszeiten?.data;
  return { isLoading, error, sollarbeitszeiten };
};
