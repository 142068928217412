import React, { useState } from "react";
import { globalMemory } from "../../../globals";
import { Button, Divider, Form, Input, Modal, TimePicker } from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { GENERICTYPE_MODAL_SIZE } from "../../../types/genericTypes";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { useArbeitszeitStore } from "../../../store/zustandArbeitszeitenStore";
import { tippy } from "@tippyjs/react";

const IWIstArbeitszeitenModal = (props: any) => {
  const [form] = Form.useForm();

  const { isModalIstZeitenVisible, setIsModalIstZeitenVisible, pausenVisible, setPausenVisible } =
    useArbeitszeitStore();
  const { displayFirstOpen, setDisplayFirstOpen } = useGenericStore();

  if (displayFirstOpen) {
    setDisplayFirstOpen(false);
    form.resetFields();
  }

  let arrPausenInitial = [
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
    { von: "00:00", bis: "00:00" },
  ];
  if (props.arrPausen.length > 0) {
    for (let i = 0; i < props.arrPausen.length; i++) {
      arrPausenInitial[i].von = props.arrPausen[i].von;
      arrPausenInitial[i].bis = props.arrPausen[i].bis;
    }
  }

  const addPause = () => {
    setPausenVisible(pausenVisible + 1);
  };

  const deletePause = () => {
    setPausenVisible(pausenVisible - 1);
  };

  /**
   *  Korrektur zur APi schicken
   */
  const handleUAAbschicken = (values: any) => {
    props.submitAction(values);
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  return (
    <Modal
      title={globalMemory.modalTitle}
      width={GENERICTYPE_MODAL_SIZE.GENERICTYPE_MODAL_SIZE_LARGE}
      bodyStyle={{ overflow: "auto", height: props.arrPausen.length * 30 + 300 + "px" }}
      open={isModalIstZeitenVisible}
      footer={false}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
    >
      {globalMemory.ma}
      <Divider></Divider>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleUAAbschicken}
        autoComplete="off"
      >
        <div style={{ overflow: "auto", height: "100%" }}>
          <Form.Item label="Arbeitszeit:" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="ArbeitszeitAb"
              style={{ display: "inline-block" }}
              initialValue={dayjs("01.01.2000 " + props.strVon)}
            >
              <TimePicker
                defaultValue={dayjs()}
                allowClear={false}
                format={"HH:mm"}
                locale={locale}
                showNow={false}
                minuteStep={5}
                disabledTime={disabledDateTime}
                changeOnBlur={true}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              />
            </Form.Item>
            <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
              <label>bis</label>
            </Form.Item>
            <Form.Item
              label=""
              name="ArbeitszeitBis"
              style={{ display: "inline-block", margin: "0 8px" }}
              initialValue={dayjs("01.01.2000 " + props.strBis)}
            >
              <TimePicker
                defaultValue={dayjs()}
                allowClear={false}
                format={"HH:mm"}
                locale={locale}
                showNow={false}
                minuteStep={5}
                disabledTime={disabledDateTime}
                changeOnBlur={true}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                className={style.iwMonthPicker}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Pause 1:" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="pause1Von"
              style={{ display: "inline-block" }}
              initialValue={dayjs("01.01.2000 " + arrPausenInitial[0].von)}
            >
              <TimePicker
                defaultValue={dayjs()}
                allowClear={true}
                format={"HH:mm"}
                locale={locale}
                inputReadOnly
                changeOnBlur={true}
                minuteStep={5}
                showNow={false}
                disabledTime={disabledDateTime}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                className={style.iwMonthPicker}
              />
            </Form.Item>
            <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
              <label>bis</label>
            </Form.Item>
            <Form.Item
              label=""
              name="pause1Bis"
              style={{ display: "inline-block", margin: "0 8px" }}
              initialValue={dayjs("01.01.2000 " + arrPausenInitial[0].bis)}
            >
              <TimePicker
                defaultValue={dayjs()}
                allowClear={true}
                format={"HH:mm"}
                locale={locale}
                inputReadOnly
                changeOnBlur={true}
                minuteStep={5}
                disabledTime={disabledDateTime}
                showNow={false}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                className={style.iwMonthPicker}
              />
            </Form.Item>
            {pausenVisible < 2 ? (
              <PlusCircleTwoTone
                twoToneColor={"#d1e0ec"}
                style={{ fontSize: 25, margin: "0 5px" }}
                onClick={addPause}
              />
            ) : null}
          </Form.Item>

          {pausenVisible >= 2 ? (
            <Form.Item label="Pause 2:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause2Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[1].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                <label>bis</label>
              </Form.Item>
              <Form.Item
                label=""
                name="pause2Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[1].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 2 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />

                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 3 ? (
            <Form.Item label="Pause 3:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause3Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[2].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause3Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[2].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 3 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 4 ? (
            <Form.Item label="Pause 4:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause4Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[3].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause4Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[3].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 4 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 5 ? (
            <Form.Item label="Pause 5:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause5Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[4].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause5Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[4].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 5 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 6 ? (
            <Form.Item label="Pause 6:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause6Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[5].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause6Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[5].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 6 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 7 ? (
            <Form.Item label="Pause 7:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause7Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[6].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause7bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[6].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 7 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 8 ? (
            <Form.Item label="Pause 8:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause8Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[7].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause8Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[7].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 8 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 9 ? (
            <Form.Item label="Pause 9:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause9Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[8].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause9Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[8].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {pausenVisible == 9 ? (
                <>
                  <PlusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={addPause}
                  />
                  <MinusCircleTwoTone
                    twoToneColor={"#d1e0ec"}
                    style={{ fontSize: 25, margin: "0 5px" }}
                    onClick={deletePause}
                  />
                </>
              ) : null}
            </Form.Item>
          ) : null}
          {pausenVisible >= 10 ? (
            <Form.Item label="Pause 10:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause10Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[9].von)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause10Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("01.01.2000 " + arrPausenInitial[9].bis)}
              >
                <TimePicker
                  defaultValue={dayjs()}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <MinusCircleTwoTone
                twoToneColor={"#d1e0ec"}
                style={{ fontSize: 25, margin: "0 5px" }}
                onClick={deletePause}
              />
            </Form.Item>
          ) : null}
        </div>

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "15px", width: "595px" }}
          initialValue={""}
        >
          <Input defaultValue={""} style={{ marginLeft: "5px", backgroundColor: "#e1f0fc" }}></Input>
        </Form.Item>
        <Divider></Divider>

        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          <Button
            type="primary"
            onClick={() => setIsModalIstZeitenVisible(false)}
            style={{
              backgroundColor: "#f5f5f5",
              color: "#4096ff",
              marginLeft: "403px",
              marginRight: "5px",
            }}
          >
            Abbrechen
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(IWIstArbeitszeitenModal);
