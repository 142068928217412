import { APPIDENT } from "../constants";

import {
  typeDeleteZeitraum,
  typeKopiereZeitraum,
  typeSetAbwesenheit,
  typeSetSollArbeitszeitenTag,
} from "../types/sollArbeitszeitenTypes";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
  filialToken: string[];
  objectSetSollArbeitszeitenTag: typeSetSollArbeitszeitenTag;
}

export const getQuerySetSollArbeitszeit = ({ userToken, filialToken, objectSetSollArbeitszeitenTag }: QueryParams) => ({
  query: `mutation {
      setSollArbeitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        zeitKontext: ${objectSetSollArbeitszeitenTag.zeitKontext},
        dbid: ${objectSetSollArbeitszeitenTag.dbid},
        maid: ${objectSetSollArbeitszeitenTag.maid},
        datum: "${objectSetSollArbeitszeitenTag.datum}",
        datumVon: "${objectSetSollArbeitszeitenTag.datumVon}",
        datumBis: "${objectSetSollArbeitszeitenTag.datumBis}",
        arbeitszeit: "${objectSetSollArbeitszeitenTag.arbeitszeit}",
        uhrzeitVon: "${objectSetSollArbeitszeitenTag.uhrzeitVon}",
        uhrzeitBis: "${objectSetSollArbeitszeitenTag.uhrzeitBis}",
        pause: "${objectSetSollArbeitszeitenTag.pause}",
        pauseFix: ${objectSetSollArbeitszeitenTag.pauseFix},
        pause1Von: "${objectSetSollArbeitszeitenTag.pause1Von}",
        pause1Bis: "${objectSetSollArbeitszeitenTag.pause1Bis}",
        pause2Von: "${objectSetSollArbeitszeitenTag.pause2Von}",
        pause2Bis: "${objectSetSollArbeitszeitenTag.pause2Bis}",
        turnusTyp: ${objectSetSollArbeitszeitenTag.turnusTyp},
        bemerkung: "${objectSetSollArbeitszeitenTag.bemerkung}",
        ) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            erfolgreich
            dbid
            ueberschrift
            infotext
        }
      }
      }
  `,
});

interface QueryParamsAbwesenheit {
  userToken: string;
  filialToken: string[];
  objectSetAbwesenheit: typeSetAbwesenheit;
}
export const getQuerySetAbwesenheit = ({ userToken, filialToken, objectSetAbwesenheit }: QueryParamsAbwesenheit) => ({
  query: `mutation {
      setAbwesenheitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        dbid: ${objectSetAbwesenheit.dbid},
        abwesendTyp:${objectSetAbwesenheit.abwesendTyp},
        maid: ${objectSetAbwesenheit.maid},
        datum: "${objectSetAbwesenheit.datum}",
        datumVon: "${objectSetAbwesenheit.datumVon}",
        datumBis: "${objectSetAbwesenheit.datumBis}",
        uhrzeitVon: "${objectSetAbwesenheit.uhrzeitVon}",
        uhrzeitBis: "${objectSetAbwesenheit.uhrzeitBis}",
        turnusTyp: ${objectSetAbwesenheit.turnusTyp},
        halberTag: ${objectSetAbwesenheit.halberTag},
        bemerkung: "${objectSetAbwesenheit.bemerkung}",
       
        ) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            erfolgreich
            dbid
            ueberschrift
            infotext
        }
      }
      }
  `,
});

interface QueryParamsKopieren {
  userToken: string;
  objectKopiere: typeKopiereZeitraum;
}
export const getQueryKopiereZeitraum = ({ userToken, objectKopiere }: QueryParamsKopieren) => ({
  query: `mutation {
    copySollArbeitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        quellFilialToken: "${objectKopiere.quellFilialToken}",
        quellMaid: ${objectKopiere.quellMaid},
        quellDatumVon: "${objectKopiere.quellDatumVon}",
        quellDatumBis: "${objectKopiere.quellDatumBis}",
        zielFilialToken: "${objectKopiere.zielFilialToken}",
        zielMaid: ${objectKopiere.zielMaid},
        zielDatumVon: "${objectKopiere.zielDatumVon}",) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            erfolgreich
            dbid
            ueberschrift
            infotext
        }
    }
}
  `,
});

interface QueryParamsDelete {
  userToken: string;
  filialToken: string[];
  objectDelete: typeDeleteZeitraum;
}

export const getQueryDeleteZeitraumArbeitszeit = ({ userToken, filialToken, objectDelete }: QueryParamsDelete) => ({
  query: `mutation {
    deleteSollArbeitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        id: ${objectDelete.id},
        datum: "${objectDelete.datum}",
        zeitKontext: ${objectDelete.zeitKontext},) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            erfolgreich
            dbid
            ueberschrift
            infotext
        }
    }
}
  `,
});

export const getQueryDeleteZeitraumAbwesenheit = ({ userToken, filialToken, objectDelete }: QueryParamsDelete) => ({
  query: `mutation {
    deleteAbwesenheitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        id: ${objectDelete.id},
        datum: "${objectDelete.datum}",
        zeitKontext: ${objectDelete.zeitKontext},) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            erfolgreich
            dbid
            ueberschrift
            infotext
        }
    }
}
  `,
});
