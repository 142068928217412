import { Col, Layout, Menu, Row, Avatar, Button, Popover, Modal, List } from "antd";

import { API_PORT } from "../../../constants";

import style from "./NavTop.module.css";

import logo from "../../images/connect.svg";

import { useHistory } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { APISDStatistik } from "../../../types/apiTypes";
import { getWertFromlocalStorage } from "../../../utils/functions";
import { isCompanyAdministrator, isSalonManagerAndAbove } from "../../../utils/functionsSecurity";
import React from "react";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { cmdParameter } from "../../App/App";
import { GENERICTYPE_PARAMETER_ACTIVEMODUL } from "../../../types/genericTypes";
import { useAppStore } from "../../../store/zustandAppStore";
import { globalMemory } from "../../../globals";

const { Header } = Layout;

const NavTop = (props: any) => {
  const { setLoggedIn, isModalOpen, setIsModalOpen, setBenutzername, setPasswort } = useAppStore();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  let history = useHistory();
  const stammdaten = useStammdaten();
  globalMemory.oeffnungszeitenStammdaten = stammdaten.stammdaten.oeffnungszeitenJeFiliale;
  globalMemory.umstellungJeFilialeStammdaten = stammdaten.stammdaten.datumUmstellungNeuesAZeitModellJeFiliale;
  const statistikStammdaten = stammdaten.stammdaten.statistiken;

  const userInitials: string =
    getWertFromlocalStorage("loginInfo", "vorname").charAt(0) + getWertFromlocalStorage("loginInfo", "name").charAt(0);

  const userUsername: string = getWertFromlocalStorage("loginInfo", "benutzername");

  const isStatistikVorhanden: boolean = Array.isArray(statistikStammdaten) && statistikStammdaten.length !== 0;

  function goToDatev() {
    history.push("/datev");
  }

  function goToHome() {
    if (
      cmdParameter.activeModules.includes(GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT)
    )
      history.push("/home");
  }
  function goToArbeitszeiten() {
    history.push("/arbeitszeiten");
  }

  function goToSollArbeitszeiten() {
    history.push("/sollarbeitszeiten");
  }

  function goToPlaner() {
    history.push("/planer");
  }

  function goToUserManager() {
    history.push("/user_manager");
  }

  function goToUserStatistik(statistikID: number) {
    history.push("/statistik/" + statistikID);
  }

  function logout() {
    delete localStorage.loginInfo;
    setBenutzername("");
    setPasswort("");
    setLoggedIn(false);
    history.push("/home");
  }

  const renderStatistikSubMenu = (statistik: APISDStatistik) => (
    <Menu.Item
      key={`s_${statistik.id}`}
      style={{ backgroundColor: "white", width: "300px", borderRadius: "0px", margin: 0 }}
      onClick={() => goToUserStatistik(statistik.id)}
    >
      {statistik.titel}
    </Menu.Item>
  );

  const userAgent = navigator.userAgent.toLowerCase();
  const downloadFileName =
    "https://www.e-cut.de/download4/connect/e-cut Connect Setup " + process.env.REACT_APP_VERSION + ".exe";

  const profilPopupContent = (
    <div className={style.profilPopupContainer}>
      <Avatar className={style.profilPopupAvatar} size="large" gap={7}>
        {userInitials}
      </Avatar>
      <p>{getWertFromlocalStorage("loginInfo", "vorname") + " " + getWertFromlocalStorage("loginInfo", "name")}</p>
      <hr />
      {cmdParameter.activeModules.includes(
        GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
      ) ? (
        <>
          <Button className={style.logoutBtn} onClick={logout}>
            Abmelden
          </Button>
          <hr />

          <p onClick={showModal}>
            {process.env.REACT_APP_VERSION}{" "}
            {
              // @ts-ignore
              API_PORT === "8080" || API_PORT === "8443" ? "dev" : ""
            }
          </p>
          <p>
            <a onClick={showModal}>Was ist neu?</a>
          </p>
        </>
      ) : null}
      {/*// <p>{userAgent.includes("electron") ? "" : <a href={downloadFileName}>App herunterladen</a>} </p>*/}
    </div>
  );

  const data = [
    {
      title: "Erweiterung Statistikmodul",
      description: "",
    },
    {
      title: "Verbesserung der Menuführung",
      description: "",
    },
    {
      title: "Viele kleine Verbesserungen",
      description: "",
    },
  ];

  return (
    <Header className="header">
      <Row>
        <Col span={20}>
          {cmdParameter.activeModules.includes(
            GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
          ) ? (
            <div className={style.logo}>
              <img src={logo} className="App-logo" alt="e-cut Connect" onClick={goToHome} />
            </div>
          ) : null}
          <Menu theme="dark" mode="horizontal">
            {isCompanyAdministrator() &&
            !props.isConnectLoading &&
            cmdParameter.activeModules.includes(
              GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
            ) ? (
              <Menu.Item key="1" onClick={goToDatev}>
                DATEV-Export
              </Menu.Item>
            ) : null}

            {cmdParameter.activeModules.includes(
              GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
            ) && !props.isConnectLoading ? (
              <SubMenu key="2" title="Statistiken" style={{ borderRadius: "0px" }}>
                {isStatistikVorhanden ? (
                  statistikStammdaten.map(renderStatistikSubMenu)
                ) : (
                  <Menu.Item key={"keine Statistik"} style={{ backgroundColor: "white", borderRadius: "0px" }}>
                    Statistiken werden geladen...
                  </Menu.Item>
                )}
              </SubMenu>
            ) : null}

            {isSalonManagerAndAbove() &&
            !props.isConnectLoading &&
            !userUsername.includes("@wella") &&
            !userUsername.includes("@keller") &&
            cmdParameter.activeModules.includes(
              GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
            ) ? (
              <>
                <SubMenu key="3" title="Arbeitszeiten" style={{ borderRadius: "0px" }}>
                  {userUsername.includes("@arto") || userUsername.includes("@messe") ? (
                    <Menu.Item
                      key="3.2"
                      onClick={goToSollArbeitszeiten}
                      style={{ backgroundColor: "white", width: "200px", borderRadius: "0px", margin: 0 }}
                    >
                      Dienstpläne
                    </Menu.Item>
                  ) : null}
                  <Menu.Item
                    key="3.1"
                    onClick={goToArbeitszeiten}
                    style={{ backgroundColor: "white", width: "200px", borderRadius: "0px", margin: 0 }}
                  >
                    Arbeitszeitnachweis
                  </Menu.Item>
                </SubMenu>
              </>
            ) : null}

            {(userUsername.includes("@arto") ||
              userUsername.includes("@messe") ||
              userUsername.includes("@keller") ||
              userUsername.includes("@lars-cordes-hairdesign")) &&
            !props.isConnectLoading &&
            cmdParameter.activeModules.includes(
              GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
            ) ? (
              <Menu.Item key="4" onClick={goToPlaner}>
                Terminplaner
              </Menu.Item>
            ) : null}
            {/* {false ? (
              <SubMenu key="5" title="Verwaltung" theme={"dark"} style={{ borderRadius: "0px" }}>
                {isCompanyAdministrator() ? (
                  <Menu.Item onClick={goToUserManager}>Mitarbeiterverwaltung</Menu.Item>
                ) : (
                  <Menu.Item>Sie haben keine Berechtigung dies zu sehen</Menu.Item>
                )}
              </SubMenu>
            ) : null} */}
            {/*
            <Menu.Item key="4">Einstellungen</Menu.Item>
            <Menu.Item key="5">Hilfe</Menu.Item>
          */}
          </Menu>
        </Col>
        <Col span={4} className={style.versionnr}>
          <Popover placement="bottom" title={""} content={profilPopupContent} className={style.popover}>
            <Avatar className={style.avatar} size="large" gap={0}>
              {userInitials}
            </Avatar>
          </Popover>
        </Col>
      </Row>
      <Modal
        title=""
        open={isModalOpen}
        closable={false}
        width={800}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Schließen
          </Button>,
        ]}
      >
        <p>
          <img src={logo} width={300} className="App-logo" alt="e-cut Connect" onClick={goToHome} />
        </p>
        <p>
          <br />
          <b>
            Was ist neu in Version {process.env.REACT_APP_VERSION}
            {
              // @ts-ignore
              API_PORT === "8080" || API_PORT === "8443" ? "dev" : ""
            }
          </b>
        </p>

        <p>
          <List
            size="small"
            split={false}
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta avatar={<PlusCircleTwoTone />} title={item.title} description={item.description} />
              </List.Item>
            )}
          />
        </p>
      </Modal>
    </Header>
  );
};

export default NavTop;
