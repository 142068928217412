import React from "react";

import { Layout, Result, Typography } from "antd";

import { useParams } from "react-router";
import style from "../Datev/Datev.module.css";

const { Content } = Layout;
const { Title } = Typography;

const Planer = (props: any) => {
  const { type, token = "" } = useParams<{ type: string; token: string }>();

  const filialToken = token;

  if (filialToken === "") {
    return (
      <Content className="global-layout-background global-content">
        <div className={style.headline}>
          <Result
            title="Noch keine Filiale ausgewählt"
            extra={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <text style={{ textAlign: "left" }}>
                  <div>Bitte eine Filiale auswählen</div>
                </text>
              </div>,
            ]}
          />
        </div>
      </Content>
    );
  } else {
    return (
      <Content className="global-layout-background global-content">
        {/*TODO: in schön machen*/}
        <iframe
          title=""
          src={"https://www.e-cut.de/mobile@connect/index.html?connectToken=" + filialToken}
          style={{
            position: "absolute",
            left: props.isNavLeftVisible ? 375 : 0,
            top: 64,
            width: props.isNavLeftVisible ? window.screen.width - 380 : window.screen.width,
            height: "93%",
          }}
        ></iframe>
      </Content>
    );
  }
};

export default Planer;
