import React from "react";
import { Layout, Row, Col } from "antd";
import HoverImage from "react-hover-image";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import btnDatev from "../../images/btnDatev.svg";
import btnArbeitszeit from "../../images/btnArbeitszeit.svg";
import btnDatevHover from "../../images/btnDatevHover.svg";
import btnArbeitszeitHover from "../../images/btnArbeitszeitHover.svg";

import styleGlobal from "../Global.module.css";
import style from "./Home.module.css";
import { isCompanyAdministrator, isSalonManagerAndAbove } from "../../../utils/functionsSecurity";
import { getWertFromlocalStorage } from "../../../utils/functions";

const { Content } = Layout;

const Home = () => {
  let history = useHistory();

  const userUsername: string = getWertFromlocalStorage("loginInfo", "benutzername");

  function goToDatev() {
    history.push("/datev");
  }

  return (
    <Content className={styleGlobal.content}>
      <Row className={style.btnMargin}>
        {isCompanyAdministrator() ? (
          <Col
            className={style.btnBackground}
            id="btnBuchhaltung"
            xs={{ span: 5, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            onClick={goToDatev}
          >
            <Link to="">
              <div>
                <HoverImage className={style.icons} src={btnDatev} hoverSrc={btnDatevHover} />
              </div>
            </Link>
          </Col>
        ) : null}
        {isSalonManagerAndAbove() && !userUsername.includes("@wella") && !userUsername.includes("@keller") ? (
          <Col
            className={style.btnBackground}
            id="btnArbeitszeit"
            xs={{ span: 5, offset: 1 }}
            lg={{ span: 6, offset: 2 }}
          >
            <Link to="/arbeitszeiten">
              <div>
                <HoverImage className={style.icons} src={btnArbeitszeit} hoverSrc={btnArbeitszeitHover} />
              </div>
            </Link>
          </Col>
        ) : null}
      </Row>
    </Content>
  );
};

export default Home;
