import React, { useCallback } from "react";
import { globalMemory } from "../../../globals";
import { Button, Divider, Form, Input, Modal } from "antd";
import { fetchData, getWertFromlocalStorage } from "../../../utils/functions";
import { getQueryKorrekturZeit, QueryParamsKorrekturzeit } from "../../../hooks/queriesIstArbeitszeiten";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { useArbeitszeitStore } from "../../../store/zustandArbeitszeitenStore";
import { getMinutenFromUhrzeitStdMin, wandelMinutenToStundeMinute } from "../../../utils/functionUhrzeit";
import { tippy } from "@tippyjs/react";

const IWKorrektur = (props: any) => {
  const { displayFirstOpen, setDisplayFirstOpen } = useGenericStore();
  const { isModalKorrekturVisible, detailDataKorrektur, setDetailDataKorrektur } = useArbeitszeitStore();
  const [form] = Form.useForm();

  const closeModalAction = useCallback(() => {
    props.closeAction(false);
  }, [props.closeAction]);

  if (displayFirstOpen) {
    form.resetFields();
    setDisplayFirstOpen(false);
  }

  /**
   *  Korrektur zur APi schicken
   */
  const handleUAAbschicken = (values: any) => {
    props.closeAction(false);
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    let maid = globalMemory.maid;
    let filialToken: string[] = globalMemory.modalFilialtoken;
    let jahr = globalMemory.modalAktDate.year();
    let monat = globalMemory.modalAktDate.month() + 1;
    let korrekturwert = "" + values.korrektur + ":00";
    let typ = "Z";
    fetchData(
      getQueryKorrekturZeit({
        userToken,
        filialToken,
        typ,
        maid,
        jahr,
        monat,
        korrekturwert,
      } as QueryParamsKorrekturzeit)
    ).then(function (response) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.setKorrekturzeit;

      if (temp.data.erfolgreich) {
        props.closeAction(false);
      }
    });
  };

  const handleLeerenButton = () => {
    let alt = detailDataKorrektur.summeUeberstunden;
    if (alt.startsWith("+")) {
      alt = "-" + alt.substring(1);
    } else {
      alt = "+" + alt.substring(1);
    }
    form.setFieldValue("korrektur", alt);
    form.setFieldValue("uebneu", "00:00");
  };

  const handleOnChangeKorrektur = () => {
    let alt = detailDataKorrektur.summeUeberstunden;
    let korrektur = form.getFieldValue("korrektur");

    let alterWert = getMinutenFromUhrzeitStdMin(alt.replace("+", "").replace("-", ""));
    let korrekturWert = getMinutenFromUhrzeitStdMin(korrektur.replace("+", "").replace("-", ""));

    let neuerWert: string;

    if (korrektur.includes("-")) {
      neuerWert = wandelMinutenToStundeMinute(alterWert - korrekturWert);
    } else {
      neuerWert = wandelMinutenToStundeMinute(alterWert + korrekturWert);
    }

    form.setFieldValue("uebneu", neuerWert);
  };

  return (
    <Modal
      title={globalMemory.modalTitle}
      width={450}
      open={isModalKorrekturVisible}
      footer={false}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
    >
      {globalMemory.ma} / {detailDataKorrektur.korrekturDate}
      <Divider></Divider>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 450 }}
        initialValues={{ remember: true }}
        onFinish={handleUAAbschicken}
        autoComplete="off"
      >
        <Form.Item
          label="Überstunden alt:"
          name="uebalt"
          style={{ marginBottom: "25px" }}
          initialValue={detailDataKorrektur.summeUeberstunden}
        >
          <Input
            defaultValue={detailDataKorrektur.summeUeberstunden}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "Summe der Überstunden alt",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            disabled={true}
          ></Input>
        </Form.Item>
        <Form.Item
          label="Korrektur:"
          name="korrektur"
          style={{ marginBottom: "25px" }}
          initialValue={detailDataKorrektur.korrektur}
          shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
        >
          <Input
            defaultValue={detailDataKorrektur.korrektur}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "Korrektur der Überstunden + oder -",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            onBlur={handleOnChangeKorrektur}
          ></Input>
        </Form.Item>
        <Button
          type="primary"
          onClick={handleLeerenButton}
          style={{
            position: "absolute",
            left: 300,
            top: 180,
            backgroundColor: "#4096ff",
            color: "#f5f5f5",
          }}
        >
          Leeren
        </Button>
        <Form.Item
          label="Überstunden neu:"
          name="uebneu"
          style={{ marginBottom: "25px" }}
          initialValue={detailDataKorrektur.summeUeberstunden}
        >
          <Input
            defaultValue={detailDataKorrektur.summeUeberstunden}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content: "Summe der Überstunden neu",
                placement: "top",
                interactive: true,
                arrow: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            disabled={true}
          ></Input>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={closeModalAction}
            style={{
              backgroundColor: "#f5f5f5",
              color: "#4096ff",
              left: 197,
              top: 30,
              marginLeft: "50px",
            }}
          >
            Abbrechen
          </Button>{" "}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              position: "absolute",
              left: 350,
              top: 30,
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Ok
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(IWKorrektur);
