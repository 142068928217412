import React from "react";
import { Form, DatePicker, Col } from "antd";
import style from "./IWRangePicker.module.css";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../../../constants";
import { APIEnumDatepicker } from "../../../types/apiTypes";
import dayjs from "dayjs";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

const dateFormatMonth = "MMMM YYYY"; // Monatsname + Jahr vierstellig
const dateFormatDay = "DD.MM.YYYY"; // Monatsname + Jahr vierstellig
const dateFormatYear = "YYYY"; // Monatsname + Jahr vierstellig
const dateFormatQuarter = "YYYY"; // Monatsname + Jahr vierstellig

const { RangePicker } = DatePicker;
const IWRangePicker = (props: any) => {
  let strDateFormat;
  let strDateType: string;

  if (APIEnumDatepicker.YEAR_YEAR.includes(props.range) || APIEnumDatepicker.YEAR.includes(props.range)) {
    strDateFormat = dateFormatYear;
    strDateType = "year";
  } else if (APIEnumDatepicker.DAY_DAY.includes(props.range) || APIEnumDatepicker.DAY.includes(props.range)) {
    strDateFormat = dateFormatDay;
    strDateType = "day";
  } else if (APIEnumDatepicker.MONTH_MONTH.includes(props.range) || APIEnumDatepicker.MONTH.includes(props.range)) {
    strDateFormat = dateFormatMonth;
    strDateType = "month";
  } else if (
    APIEnumDatepicker.QUARTER_QUARTER.includes(props.range) ||
    APIEnumDatepicker.QUARTER.includes(props.range)
  ) {
    strDateFormat = dateFormatQuarter;
    strDateType = "quarter";
  } else {
    strDateFormat = dateFormatMonth;
    strDateType = "month";
  }

  // Berechnung der Quartale des Vorjahrs + das aktuelle Quartal

  let startLastYear = dayjs().subtract(1, "years").startOf("years");
  let endLastYear = dayjs().subtract(1, "years").endOf("years");
  let startLastMonth = dayjs().subtract(1, "month").startOf("month");
  let endLastMonth = dayjs().subtract(1, "month").endOf("month");
  let startLastQ1 = dayjs().startOf("year").add(-1, "years").format();
  let endLastQ1 = dayjs().startOf("year").add(-1, "years").add(2, "months").endOf("month").format();
  let startLastQ2 = dayjs(startLastQ1).add(3, "months").format();
  let endLastQ2 = dayjs(endLastQ1).add(3, "months").endOf("month").format();
  let startLastQ3 = dayjs(startLastQ2).add(3, "months").format();
  let endLastQ3 = dayjs(endLastQ2).add(3, "months").endOf("month").format();
  let startLastQ4 = dayjs(startLastQ3).add(3, "months").format();
  let endLastQ4 = dayjs(endLastQ3).add(3, "months").endOf("month").format();

  let startThisQ1 = dayjs().startOf("year").format();
  let endThisQ1 = dayjs().startOf("year").add(2, "months").endOf("month").format();
  let startThisQ2 = dayjs(startThisQ1).add(3, "months").format();
  let endThisQ2 = dayjs(endThisQ1).add(3, "months").endOf("month").format();
  let startThisQ3 = dayjs(startThisQ2).add(3, "months").format();
  let endThisQ3 = dayjs(endThisQ2).add(3, "months").endOf("month").format();
  let startThisQ4 = dayjs(startThisQ3).add(3, "months").format();
  let endThisQ4 = dayjs(endThisQ3).add(3, "months").endOf("month").format();

  let quarterStartDate = dayjs().startOf("quarter");
  let quarterEndDate = dayjs().endOf("quarter");
  //let endMonth: number = 3 * parseInt(String(currentQuarter)); //The last month of the quarter
  /* Format the number of months
  if (endMonth < 10) {
    endMonth = Number("0" + endMonth);
  } else {
    endMonth += Number("");
  }
  */

  return (
    <>
      <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start", margin: "8px" }}>
        Betrachtungszeitraum:&nbsp;
      </Col>
      <Col lg={9} xl={6} xxl={5}>
        <Form.Item className={style.zeitraumPicker} name="range-picker">
          <RangePicker
            defaultValue={[DEFAULT_DATEPICKER_START_MOMENT, DEFAULT_DATEPICKER_END_MOMENT]}
            format={strDateFormat}
            onChange={(val) => props.onClick(val)}
            allowClear={false}
            // picker={strDateType as any} //Verursacht Bug bei der Auswahl von Jahr Monat
            locale={locale}
            ranges={{
              "Aktueller Monat": [dayjs().startOf("month"), dayjs().endOf("month")],
              Vormonat: [dayjs(startLastMonth), dayjs(endLastMonth)],
              "Aktuelles Quartal": [dayjs(quarterStartDate), dayjs(quarterEndDate)],
              "1. Quartal Aktuelle Jahr": [dayjs(startThisQ1), dayjs(endThisQ1)],
              "2. Quartal Aktuelle Jahr": [dayjs(startThisQ2), dayjs(endThisQ2)],
              "3. Quartal Aktuelle Jahr": [dayjs(startThisQ3), dayjs(endThisQ3)],
              "4. Quartal Aktuelle Jahr": [dayjs(startThisQ4), dayjs(endThisQ4)],
              "1. Quartal Vorjahr": [dayjs(startLastQ1), dayjs(endLastQ1)],
              "2. Quartal Vorjahr": [dayjs(startLastQ2), dayjs(endLastQ2)],
              "3. Quartal Vorjahr": [dayjs(startLastQ3), dayjs(endLastQ3)],
              "4. Quartal Vorjahr": [dayjs(startLastQ4), dayjs(endLastQ4)],
              "Aktuelles Jahr": [dayjs().startOf("year"), dayjs()],
              Vorjahr: [dayjs(startLastYear), dayjs(endLastYear)],
            }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default IWRangePicker;
