import { useQuery } from "react-query";
import { fetchData } from "../utils/functions";
import { APILoginInfo } from "../types/apiTypes";
import { APPIDENT } from "../constants";
import { notification } from "antd";
const pkg = require("../../package.json");

interface QueryParams {
  benutzername: string;
  passwort: string;
}

export const getQueryLogin = ({ benutzername, passwort }: QueryParams) => ({
  query: `query {
        login (
          appIdent: ${APPIDENT},
          version: "${pkg.version}",    
          benutzername: "${benutzername}",
          passwort: "${passwort}",
        ) 
        {
          header {
            success,
            errorCode,
            errorText
        },
        data {
            benutzername,
            userToken,
            rolle,
            name,
            vorname,
            email
        }
        }
      }`,
});

export const useLogin = (queryParams: QueryParams, queryOptions = {}) => {
  let queryObject = getQueryLogin(queryParams);
  let { isLoading, error, data } = useQuery(["login", queryObject.query], () => fetchData(queryObject), queryOptions);

  const loginInfo: APILoginInfo = data?.data?.data?.login?.data ?? {};
  if (data?.data?.data?.login?.header?.errorCode !== 0 && queryParams.passwort.length !== 0 && !isLoading) {
    notification.open({
      message: "Fehlerhafte Eingabe",
      description: data?.data?.data?.login?.header?.errorText,
      duration: 15,
      key: "login_Warn",
      placement: "top",
    });
  }

  if (loginInfo.benutzername) {
    localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
  }

  return { isLoading, error, loginInfo };
};
