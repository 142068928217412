import React from "react";
import { Layout, Typography } from "antd";

const { Content } = Layout;
const { Title } = Typography;

const UserManager = () => {
  return (
    <Content className="global-layout-background global-content">
      <div id="headline">
        <Title level={2}>Mitarbeiterverwaltung</Title>
      </div>
    </Content>
  );
};

export default UserManager;
