import React from "react";
import { Form, DatePicker, Col } from "antd";
import style from "./IWDatePicker.module.css";
import { DEFAULT_DATEPICKER_START_MOMENT } from "../../../constants";
import { APIEnumDatepicker, APISDDatumUmstellungNeuesAZeitModellJeFiliale } from "../../../types/apiTypes";
import "dayjs/locale/de";
import locale from "antd/es/date-picker/locale/de_DE";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { globalMemory } from "../../../globals";
import { disableDateBefore } from "../../../utils/functionsDatum";

const dateFormatMonth = "MMMM YYYY"; // Monatsname + Jahr vierstellig
const dateFormatDay = "DD.MM.YYYY"; // Monatsname + Jahr vierstellig
const dateFormatYear = "YYYY"; // Monatsname + Jahr vierstellig
const dateFormatQuarter = "YYYY"; // Monatsname + Jahr vierstellig
const dateFormatWeek = "[Wοche] w";

const IWDatePicker = (props: any) => {
  let strDateFormat;
  let strDateType: string;
  let defaultDate = DEFAULT_DATEPICKER_START_MOMENT;
  let aktToken = props.aktToken;

  if (props.defaultDate !== undefined) {
    defaultDate = props.defaultDate;
  }

  if (APIEnumDatepicker.YEAR_YEAR.includes(props.range) || APIEnumDatepicker.YEAR.includes(props.range)) {
    strDateFormat = dateFormatYear;
    strDateType = "year";
  } else if (APIEnumDatepicker.DAY_DAY.includes(props.range) || APIEnumDatepicker.DAY.includes(props.range)) {
    strDateFormat = dateFormatDay;
    strDateType = "day";
  } else if (APIEnumDatepicker.MONTH_MONTH.includes(props.range) || APIEnumDatepicker.MONTH.includes(props.range)) {
    strDateFormat = dateFormatMonth;
    strDateType = "month";
  } else if (APIEnumDatepicker.WEEK_WEEK.includes(props.range) || APIEnumDatepicker.WEEK.includes(props.range)) {
    strDateFormat = dateFormatWeek;
    strDateType = "week";
  } else if (
    APIEnumDatepicker.QUARTER_QUARTER.includes(props.range) ||
    APIEnumDatepicker.QUARTER.includes(props.range)
  ) {
    strDateFormat = dateFormatQuarter;
    strDateType = "quarter";
  } else {
    strDateFormat = dateFormatMonth;
    strDateType = "month";
  }

  let strLabel = "Betrachtungszeitraum: ";
  if (props.isLabel !== undefined && props.isLabel.includes("false")) strLabel = "";

  const renderCustomDate = (current: dayjs.Dayjs) => {
    return (
      <div className="ant-calendar-date" title="">
        {current.date()}
      </div>
    );
  };

  return (
    <>
      <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start", margin: "8px" }}>
        {strLabel}
      </Col>
      <Col lg={9} xl={6} xxl={5}>
        <Form.Item className={style.zeitraumPicker} name="month-picker">
          <DatePicker
            picker={strDateType as any}
            defaultValue={defaultDate}
            dateRender={(current) => renderCustomDate(current)}
            onChange={(val) => props.onClick(val)}
            allowClear={false}
            disabledDate={disableDateBefore}
            format={strDateFormat}
            locale={locale}
            inputReadOnly
            className={style.iwMonthPicker}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default IWDatePicker;
