import { APPIDENT } from "../constants";
import { APISetIstArbeitszeitPause } from "../types/apiTypes";
import { convertObjectToGraphQLObject } from "../utils/functions";

const pkg = require("../../package.json");

interface QueryParamsdeleteIstArbeitszeit {
  userToken: string;
  filialToken: string[];
  maid: number;
  datum: string;
}

export interface QueryParamsKorrekturzeit {
  userToken: string;
  filialToken: string[];
  maid: number;
  typ: string;
  jahr: number;
  monat: number;
  korrekturwert: string;
}

export interface QueryParamsSetIst {
  userToken: string;
  filialToken: string[];
  maid: number;
  datum: string;
  zeitVon: string;
  zeitBis: string;
  pausen: APISetIstArbeitszeitPause[];
}

export const getQuerydeleteIstArbeitszeit = ({
  userToken,
  filialToken,
  datum,
  maid,
}: QueryParamsdeleteIstArbeitszeit) => ({
  query: `mutation {
    deleteIstArbeitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        maid: ${maid},
        datum: "${datum}" ){
        header {
            success,
            errorCode,
            errorText
        },
        data {
          erfolgreich,
          dbid,
          ueberschrift,
          infotext
        }
      }
    }`,
});

export const getQueryKorrekturZeit = ({
  userToken,
  filialToken,
  typ,
  maid,
  jahr,
  monat,
  korrekturwert,
}: QueryParamsKorrekturzeit) => ({
  query: `mutation {
    setKorrekturzeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        typ: "${typ}",
        maid: ${maid},
        jahr: ${jahr},
        monat: ${monat},
        korrekturwert: "${korrekturwert}"
         ){
        header {
            success,
            errorCode,
            errorText
        },
        data {
          erfolgreich,
          dbid,
          ueberschrift,
          infotext
        }
      }
    }`,
});

export const getQuerySetIstArbeitszeit = ({
  userToken,
  filialToken,
  maid,
  datum,
  zeitVon,
  zeitBis,
  pausen,
}: QueryParamsSetIst) => ({
  query: `mutation {
    setIstArbeitszeit(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
        maid: ${maid},
        datum: "${datum}",
        zeitVon: "${zeitVon}",
        zeitBis: "${zeitBis}",
        pausen:  ${convertObjectToGraphQLObject(pausen)}
        ) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            erfolgreich,
            dbid,
            ueberschrift,
            infotext
        }
    }
}`,
});
