import { APIStatistik, APITabHeader, APITabZeile } from "../types/apiTypes";

/***
 * Abfrage auf sichtbare Spalten für Anzeige und Export
 */
export const getSichtbareSpalten = (statistikRAW: APIStatistik) => {
  const headerRAW = statistikRAW.tabHeader;
  let sichtbareSpaltenWork: number[] = [];
  if (headerRAW) {
    for (let i = 0; i < headerRAW.length; i++) {
      let spalte = headerRAW[i];
      if (spalte.sichtbar) {
        sichtbareSpaltenWork.push(i);
      }
    }
  }
  return sichtbareSpaltenWork;
};

/**
 * Abfrage ob nach Spalten oder Zeilen selected wird
 */
export const isStatistikSelectSpalte = (columnID: number) => {
  return columnID != null && columnID !== -1;
};

/**
 * Gibt die Zeile anhand des RowIndex zurück
 * @param rowIndex
 * @param statistikRAW
 */
export const getActualTableRowFromLine = (rowIndex: number, statistikRAW: APIStatistik) => {
  let stringReturn: string = "";
  const headerRAW = statistikRAW.tabHeader;
  if (headerRAW) {
    for (let i = 0; i < headerRAW.length; i++) {
      let spalte = headerRAW[i];
      if (spalte.sichtbar && i === rowIndex) {
        stringReturn = spalte.text;
      }
    }
  }
  return stringReturn;
};

/**
 * Wird nach der aktuellen Auswahl inder Selectbox die Zeile angezeigt
 * @param selectedData Auswahlmöglichkeiten
 * @param apiStatistikTabZeile aktuelle Zeile
 * @param sdStatistikSelectBox aktuelle Auswahl
 * @param sdStatistikSelectBoxColumn
 */
export const isZeileSichtbar = (
  selectedData: string,
  apiStatistikTabZeile: APITabZeile,
  sdStatistikSelectBox: number,
  sdStatistikSelectBoxColumn: number
) => {
  if (
    selectedData.length === 0 ||
    (!isStatistikSelectSpalte(sdStatistikSelectBoxColumn) &&
      selectedData.length !== 0 &&
      selectedData.includes(apiStatistikTabZeile.spalten[sdStatistikSelectBox])) ||
    isStatistikSelectSpalte(sdStatistikSelectBoxColumn)
  ) {
    return true;
  } else {
    return false;
  }
};
/**
 * Wird nach der aktuellen Auswahl die Spalte in der akutellen Zeile  angezeigt
 * @param selectedData Auswahlmöglichkeiten
 * @param apiStatistikTabZeile aktuelle Zeile
 * @param sdStatistikSelectBox aktuelle Auswahl
 * @param spalteSichtbar ist die Spalte immer sichtbbar?
 * @param aktSpalte aktuelle Spalte
 * @param statistikRAW Rohdaten des Headers
 * @param sdStatistikSelectBoxColumn
 */
export const isSpalteSichtbarZeile = (
  selectedData: string,
  apiStatistikTabZeile: APITabZeile,
  sdStatistikSelectBox: number,
  spalteSichtbar: boolean,
  aktSpalte: number,
  statistikRAW: APIStatistik,
  sdStatistikSelectBoxColumn: number
) => {
  //console.log(statistikRAW);
  if (
    (spalteSichtbar &&
      isStatistikSelectSpalte(sdStatistikSelectBoxColumn) &&
      selectedData.includes(getActualTableRowFromLine(aktSpalte, statistikRAW))) ||
    (spalteSichtbar && isStatistikSelectSpalte(sdStatistikSelectBoxColumn) && selectedData.length === 0) ||
    (!isStatistikSelectSpalte(sdStatistikSelectBoxColumn) &&
      spalteSichtbar &&
      selectedData &&
      selectedData.includes(apiStatistikTabZeile.spalten[sdStatistikSelectBox])) ||
    (spalteSichtbar && isStatistikSelectSpalte(sdStatistikSelectBoxColumn) && aktSpalte === 0) ||
    (!isStatistikSelectSpalte(sdStatistikSelectBoxColumn) && selectedData.length === 0)
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Wird nach der aktuellen Auswahl die Spalte im Header  angezeigt
 * @param spalte
 * @param selectedData
 * @param aktSpalte
 * @param sdStatistikSelectBoxColumn
 */
export const isSpalteSichtbarHeader = (
  spalte: APITabHeader,
  selectedData: string,
  aktSpalte: number,
  sdStatistikSelectBoxColumn: number
) => {
  if (
    (spalte.sichtbar && !isStatistikSelectSpalte(sdStatistikSelectBoxColumn)) ||
    (spalte.sichtbar && isStatistikSelectSpalte(sdStatistikSelectBoxColumn) && selectedData.includes(spalte.text)) ||
    (spalte.sichtbar && isStatistikSelectSpalte(sdStatistikSelectBoxColumn) && selectedData.length === 0) ||
    (spalte.sichtbar && aktSpalte === sdStatistikSelectBoxColumn)
  ) {
    return true;
  } else {
    return false;
  }
};
