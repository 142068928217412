import axios from "axios";

import { API_SERVER } from "../constants";
import { APIFiliale, APISDMitarbeiterJeFiliale, APISDOeffnungszeitenJeFiliale } from "../types/apiTypes";

const url: string = `${API_SERVER}/e-cut-graphql`;

//----------------------------------------------------------------------------------------------------------------------
export const fetchData = (query: { query: string }) =>
  axios({
    url: url,
    method: "post",
    data: query,
  });
//----------------------------------------------------------------------------------------------------------------------
export const fetchDataWithCallback = (query: any, OnSuccessFunc: any) => {
  axios({
    url: url,
    method: "post",
    data: query,
  })
    .then((res) => {
      // setTimeout(function() {
      OnSuccessFunc(res);
      // }, 1500);
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//----------------------------------------------------------------------------------------------------------------------
// Wert aus localStorage Objekt ermitteln
export const getWertFromlocalStorage = (localStorageName: string, key: string, defaultValue: string = "") => {
  const localStorageString: string | null = localStorage.getItem(localStorageName);

  if (localStorageString) {
    const obj = JSON.parse(localStorageString);

    if (obj) return obj[key];
  }

  return defaultValue;
};
//----------------------------------------------------------------------------------------------------------------------
// Währungsbeträge formatieren (Punkt zu Komma wandeln, Nullen anhängen)
export const numberFormat = (zahl: any) => {
  let zahlStr = zahl.toString();
  let zahlArr = zahlStr.split(".");

  zahlStr = zahlStr.toString().replace(".", ",");

  if (typeof zahlArr[1] !== "undefined" && zahlArr[1].length === 1) {
    zahlStr = zahlStr + "0";
  } else {
    if (!zahlStr.includes(",")) zahlStr = zahlStr + ",00";
  }

  return zahlStr;
};
//----------------------------------------------------------------------------------------------------------------------
// Zahlstring mit Komma in Zahl mit Punkt wandeln und als Number zurückgeben
export const numberFormatRevers = (zahlStr: string) => {
  if (zahlStr === "") return 0.0;

  zahlStr = zahlStr.replace(",", ".");

  try {
    return parseFloat(zahlStr);
  } catch (err) {
    return 0.0;
  }
};
//----------------------------------------------------------------------------------------------------------------------
// Mitarbeitername anhand der MitarbeiterID holen
export const getMANameFromID = (
  maID: number,
  arrMAFilialen: APISDMitarbeiterJeFiliale[],
  faID: string,
  sync: boolean
) => {
  if (arrMAFilialen.length < 2) {
    //nur eine filiale oder *
    let aktMAFilialen = arrMAFilialen[0];
    for (let i = 0; i < aktMAFilialen.mitarbeiter.length; i++) {
      let aktMA = aktMAFilialen.mitarbeiter[i];
      if (aktMA.id === maID) {
        return aktMA.name;
      }
    }
  } else {
    //mehrere filialen Sind MA ID unique
    //laufe über alle filialen
    for (let i = 0; i < arrMAFilialen.length; i++) {
      //prüfe ob MAID vorhanden und FAToken stimmt
      for (let y = 0; y < arrMAFilialen[i].mitarbeiter.length; y++) {
        let aktMA = arrMAFilialen[i].mitarbeiter[y];
        if (aktMA.id === maID) {
          return aktMA.name;
        }
      }
      //wenn ja return name
    }
  }
};
//----------------------------------------------------------------------------------------------------------------------
// Filialname anhand des Filialtoken holen
export const getFilialnameFromID = (filialID: string, arrFilialen: APIFiliale[]) => {
  for (let i = 0; i < arrFilialen.length; i++) {
    if (arrFilialen[i].filialToken === filialID) {
      return arrFilialen[i].alias;
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------
// Einfacher First Letter Uppercase
export const upperCaseStringFirstLetter = (strGot: string) => {
  return strGot.charAt(0).toUpperCase() + strGot.slice(1).toLowerCase();
};

//----------------------------------------------------------------------------------------------------------------------
// Macht aus einem Object ein graphql-konformes stringifytes Object
export const convertObjectToGraphQLObject: any = (obj: any) => {
  // Make sure we don't alter integers.
  if (typeof obj === "number") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const props = obj.map((value) => `${convertObjectToGraphQLObject(value)}`).join(",");
    return `[${props}]`;
  }

  if (typeof obj === "object") {
    const props = Object.keys(obj)
      .map((key) => `${key}:${convertObjectToGraphQLObject(obj[key])}`)
      .join(",");
    return `{${props}}`;
  }

  return JSON.stringify(obj);
};
//--------------------------------------------------------------------------------------------------------------
export const searchFilialtokenInArray = (objArray: APISDOeffnungszeitenJeFiliale[], filialtoken: string) => {
  return objArray.find((element: APISDOeffnungszeitenJeFiliale) => {
    return element.filialToken === filialtoken;
  });
};
