//---------------------------------------------------------------------------
// Uhrzeiten-Funktionen
//---------------------------------------------------------------------------
// Übergabe der Minuten seit 0:00 Uhr und Rückgabe der Zeit in std:min (Format xx:yy)
export const getUhrzeitStdMin = (minuten: number) => {
  let std: number = Math.trunc(minuten / 60);
  let restmin: number = minuten - std * 60;

  let stdStr: string = std < 10 ? "0" + std.toString() : std.toString();
  let restminStr: string = restmin < 10 ? "0" + restmin.toString() : restmin.toString();

  return stdStr.toString() + ":" + restminStr;
};

//---------------------------------------------------------------------------
// std:min (Format xx:yy) in Minuten seit 0:00 Uhr wandeln
export const getMinutenFromUhrzeitStdMin = (uhrzeit: string) => {
  if (uhrzeit === "") return 0;

  const uhrzeitA = uhrzeit.split(":");

  if (uhrzeitA[0].length === 1) uhrzeitA[0] = "0" + uhrzeitA[0];
  if (uhrzeitA[1].length === 1) uhrzeitA[1] = "0" + uhrzeitA[1];

  return parseInt(uhrzeitA[0]) * 60 + parseInt(uhrzeitA[1]);
};

//---------------------------------------------------------------------------

// Minuten seit 0:00 Uhr in Zeit std:min (Format xx:yy) wandeln
// Kann auch mit negativen Minuten umgehen
export const wandelMinutenToStundeMinute = (minuten: number) => {
  let std: number = Math.trunc(minuten / 60);

  let stdOut: string;

  if (std >= 0 && minuten >= 0) stdOut = std < 10 ? "0" + std.toString() : std.toString();
  else stdOut = std > -10 ? "-0" + (std * -1).toString() : std.toString();

  let min: number = Math.trunc(minuten % 60);

  if (min < 0) min = min * -1;

  let minutenOut: string = min < 10 ? "0" + min.toString() : min.toString();

  return stdOut + ":" + minutenOut;
};
