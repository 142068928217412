import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import deDE from "antd/lib/locale/de_DE";

import "antd/dist/reset.css";
import "./index.css";
import App from "./components/App/App";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={deDE}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
