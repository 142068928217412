import create from "zustand";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { Dictionary } from "@fullcalendar/core/internal";

const itemsDefault: ItemType[] = [
  {
    key: "1",
    label: "<div>dummy menu item</div>",
  },
];

const initEvent: Dictionary = {};

export interface GenericStateInterface {
  detailData: Dictionary;
  displayFirstOpen: boolean;
  displayModalMenu: boolean;
  displayModalMenuItems: ItemType[];
  displayModalMenuPosX: number;
  displayModalMenuPosY: number;
  searchText: string;
  searchedColumn: string;
  setDetailData: (detailData: Dictionary) => void;
  setDisplayFirstOpen: (displayFirstOpen: boolean) => void;
  setDisplayModalMenu: (displayModalMenu: boolean) => void;
  setDisplayModalMenuItems: (displayModalMenuItems: ItemType[]) => void;
  setDisplayModalMenuPosX: (displayModalMenuPosX: number) => void;
  setDisplayModalMenuPosY: (displayModalMenuPosY: number) => void;
  setSearchText: (searchText: string) => void;
  setSearchedColumn: (searchedColumn: string) => void;
}

export const useGenericStore = create<GenericStateInterface>()((set) => ({
  detailData: initEvent,
  displayFirstOpen: true,
  displayModalMenu: false,
  displayModalMenuItems: itemsDefault,
  displayModalMenuPosX: -500,
  displayModalMenuPosY: -500,
  searchText: "",
  searchedColumn: "",
  setDetailData: (detailData: Dictionary) => set({ detailData }),
  setDisplayFirstOpen: (displayFirstOpen: boolean) => set({ displayFirstOpen }),
  setDisplayModalMenu: (displayModalMenu: boolean) => set({ displayModalMenu }),
  setDisplayModalMenuItems: (displayModalMenuItems: ItemType[]) => set({ displayModalMenuItems }),
  setDisplayModalMenuPosX: (displayModalMenuPosX: number) => set({ displayModalMenuPosX }),
  setDisplayModalMenuPosY: (displayModalMenuPosY: number) => set({ displayModalMenuPosY }),
  setSearchText: (searchText: string) => set({ searchText }),
  setSearchedColumn: (searchedColumn: string) => set({ searchedColumn }),
}));
